import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useToast } from "../../../../../components";
import ProgressStepper from "../../../../../components/ProgressStepper/ProgressStepper";
import { daysAgo, formatISODate } from "../../../../../utils/datetime";
import { usePageTracker } from "../../../../../utils/googleAnalytics";
import { canViewAdvancedInsights } from "../../../../../utils/permissions";
import { FilterValue, useCreateSkillReportMutation } from "../../../../graphql";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import SkillsReportHeader from "../SkillsReportHeader";
import SkillsReportBuilderStepOne from "./SkillsReportBuilderStepOne";
import SkillsReportBuilderStepTwo, {
  SkillsReportBuilderStepTwoFormData,
} from "./SkillsReportBuilderStepTwo";
import { DateRangeState } from "./types";

interface GenerateReportData {
  positionId: string;
  candidateFilter?: string; // Not implemented yet
  dateRange: {
    start: Date;
    end: Date;
  };
  source?: string; // Not implemented yet
  skills: Array<{
    name: string;
    description?: string;
  }>;
}

const default90Days: DateRangeState = {
  start: daysAgo(90),
  end: new Date(),
};

const AnalyticsSkillsReportBuilder: React.FC = () => {
  usePageTracker("skill_report_builder");
  const navigate = useNavigate();
  const toast = useToast();

  const currentUser = useCurrentUser();
  const [builderStep, setBuilderStep] = useState(0);
  const [positionId, setPositionId] = useState<string | undefined>();
  const [candidateFilter, setCandidateFilter] = useState<string | undefined>();
  const [interviewStages, setInterviewStages] = useState<FilterValue[]>([]);
  const [minInterviewCount, setMinInterviewCount] = useState<number>(1);
  const [dateRange, setDateRange] = useState(default90Days);

  const [createSkillsReport] = useCreateSkillReportMutation();
  const handleGenerateReport = (data: GenerateReportData): void => {
    const interviewStageFilters = JSON.stringify(
      interviewStages.map((stage) => ({
        label: stage.label,
        value: stage.value,
      }))
    );
    createSkillsReport({
      variables: {
        positionIds: [data.positionId],
        dateRangeStart: formatISODate(data.dateRange.start),
        dateRangeEnd: formatISODate(data.dateRange.end),
        skills: data.skills.map(
          (skill: { name: string; description?: string }) => ({
            name: skill.name,
            description: skill.description,
          })
        ),
        interviewStageFilters,
        minimumInterviewCountFilter: minInterviewCount,
      },
      onCompleted: (data) => {
        navigate(
          `/insights/skill-report/${data.createSkillReport?.skillReport.id}`
        );
      },
      onError: (error) => {
        toast({
          title: `Error`,
          description: error.message,
          status: "error",
        });
      },
    });
  };

  const generateReport = (data: SkillsReportBuilderStepTwoFormData): void => {
    const { source, skills } = data;
    if (!positionId) {
      return;
    }
    handleGenerateReport({
      positionId,
      candidateFilter,
      dateRange,
      source,
      skills: skills.filter((skill) => skill.name && skill.name.trim() !== ""),
    });
  };

  if (!canViewAdvancedInsights(currentUser)) {
    return <Navigate to="/" replace />;
  }

  return (
    <Flex
      flexDir="column"
      minH="100vh"
      bg="rgba(35, 152, 251, 0.03)"
      pb="100px"
    >
      <SkillsReportHeader />
      <Box py="8" margin="0 auto">
        <ProgressStepper
          steps={["Basics", "Skills", "Done"]}
          activeStepIndex={builderStep || 0}
          stepBgColor="#F5F9FD"
        />
      </Box>
      {builderStep === 0 && (
        <SkillsReportBuilderStepOne
          onCancel={() =>
            setBuilderStep(1)
          } /* TODO: figure out what clicking cancel does on the first screen */
          onComplete={() => setBuilderStep(1)}
          positionId={positionId}
          candidateFilter={candidateFilter}
          dateRange={dateRange}
          interviewStages={interviewStages}
          minInterviewCount={minInterviewCount}
          onPositionIdChange={setPositionId}
          onCandidateFilterChange={setCandidateFilter}
          onDateRangeChange={setDateRange}
          onInterviewStagesChange={setInterviewStages}
          onMinInterviewCountChange={setMinInterviewCount}
        />
      )}
      {builderStep === 1 && positionId && (
        <SkillsReportBuilderStepTwo
          positionId={positionId}
          onCancel={() => setBuilderStep(0)}
          onComplete={(data) => generateReport(data)}
        />
      )}
    </Flex>
  );
};

export default AnalyticsSkillsReportBuilder;
