// Feature flagged over tab shellimport {

import { Box, Flex, Select, Text, Tooltip, useToast } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { HiOutlineClipboardCopy, HiOutlineClipboardList } from "react-icons/hi";
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiOutlineCog8Tooth,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import { Button, EmptyAIIcon, IconButton } from "../../../../components";
import useCurrentBreakpoint from "../../../../hooks/useCurrentBreakpoint";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { copy } from "../../../../utils/clipboard";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CandidateSummaryProcessingStatus,
  CandidateSummaryTemplateFragment,
} from "../../../graphql";
import { useIsExtension } from "../../../hooks/useAppEnvironmentContext";
import useTrackOnce from "../../../hooks/useTrackOnce";
import AiNotesLoading from "../../Recording/SidebarTabs/Ai/Notes/NotesQA/AiNotesLoading";
import { ColumnHeaderBorder, ColumnHeading } from "../ColumnHeading";
import NotesV2 from "./NotesV2";
import TopicCoverageListV2 from "./TopicCoverageListV2";
import TopicsAndNotesV2 from "./TopicsAndNotesV2";
import { CandidateSummary, Note, SubTopic, Topic } from "./types";
import { useShowEditCandidateSummaryTemplate } from "./useCanEditCandidateSummartTemplate";
import { formatTopic } from "./utils";

type SummaryTabContentV2Props = {
  selectedTemplate: string;
  candidateId: string;
  handleChangeTemplate: (templateId: string) => void;
  data?: CandidateSummary | null;
  templates?: CandidateSummaryTemplateFragment[] | null;
  generateSummary?: (templateId: string) => void;
  notesExpanded: boolean;
  toggleNotes: () => void;
  innerExpandedWidth: string;
};

const SummaryTabContentV2: React.FC<SummaryTabContentV2Props> = ({
  selectedTemplate,
  candidateId,
  handleChangeTemplate,
  data,
  templates,
  generateSummary,
  notesExpanded,
  toggleNotes,
  innerExpandedWidth,
}) => {
  const { windowHeight } = useWindowDimensions();
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const breakpoint = useCurrentBreakpoint();

  const topics = useMemo(() => {
    if (data) {
      return reshapeNotes(data);
    }
  }, [data]);

  const citationMap = useMemo(() => {
    let citationCount = 0;
    const cMap: { [key: string]: number } = {};
    topics?.forEach((topic) => {
      topic.subTopics?.forEach((subTopic) => {
        subTopic.notes?.forEach((note) => {
          note.citations.forEach((citation) => {
            citationCount += 1;
            cMap[citation.id] = citationCount;
          });
        });
      });
    });
    return cMap;
  }, [topics]);

  const formatAllTopics = (
    copyingTopics: Topic[]
  ): { plain: string; html: string } => {
    let plain = "";
    let html = "";

    copyingTopics?.forEach((topic) => {
      const res = formatTopic(topic, citationMap);
      plain += `${res.plain}\n\n`;
      html += `${res.html}<br>`;
    });
    return { plain, html };
  };

  const copyAllTopics = (): void => {
    if (!topics) {
      return;
    }
    const result = formatAllTopics(topics);
    copy({ plain: result.plain, html: result.html });
    toast({
      title: "Debrief copied to clipboard",
      status: "success",
      position: "top",
    });
    sendGAEvent("candidate_summary_copy_all", "call_review");
  };

  const summaryLoading =
    data &&
    data?.status !== CandidateSummaryProcessingStatus.Completed &&
    data?.status !== CandidateSummaryProcessingStatus.Failed;

  const isExtension = useIsExtension();
  const isSmallScreen = useIsSmallScreen();
  const isSmallish = isSmallScreen || breakpoint === "lg";

  const trackFirstScroll = useTrackOnce("candidate_summary_note_scroll");

  return (
    <Flex
      flexDir="row"
      gap={4}
      height={topics && !isSmallish ? "100%" : undefined}
      maxH={isSmallish ? undefined : parseInt(windowHeight) - 272}
      flex="1"
      overflow="hidden"
    >
      {isExtension || isSmallish ? (
        <CombinedTopicSummaryWrapper
          templates={templates}
          summaryExists={!!topics}
          generateSummary={generateSummary}
          handleChangeTemplate={handleChangeTemplate}
          selectedTemplate={selectedTemplate}
          copyAll={copyAllTopics}
          candidateId={candidateId}
          notesExpanded={notesExpanded}
          toggleNotes={toggleNotes}
        >
          {summaryLoading && (
            <Box mb={12}>
              <AiNotesLoading loadingText="Generating summary" />
            </Box>
          )}
          {!summaryLoading && (!topics || topics.length < 1) && (
            <Box
              alignItems="center"
              display="flex"
              flexDir="column"
              h="100%"
              width="100%"
              pb="4"
              gap="4"
            >
              <EmptyAIIcon mb="5" maxW="300px" />
              <Text align="center" maxW="300px" textAlign="center">
                No candidate debrief yet. Generating debrief notes will
                summarize all interviews for this candidate and organize them by
                competencies, when available. Try it out now.
              </Text>
              <Button
                size="sm"
                colorScheme="purple"
                bg="purple.450"
                onClick={() => {
                  if (selectedTemplate && generateSummary) {
                    generateSummary(selectedTemplate);
                  }
                }}
              >
                Generate debrief
              </Button>
              <Text maxW="300px" textAlign="center">
                This may take up to 30 seconds, depending on the number of
                interviews.
              </Text>
            </Box>
          )}
          {topics && !summaryLoading && topics.length > 0 && (
            <Box overflow="auto" mb={2} onScroll={trackFirstScroll}>
              <TopicsAndNotesV2
                topics={topics}
                onClickTimestamp={(callId: string, t: number) => {
                  window.open(`/interview/${callId}?t=${t}`);
                }}
              />
            </Box>
          )}
        </CombinedTopicSummaryWrapper>
      ) : (
        <SeparateTopicSummaryWrapper
          templates={templates}
          summaryExists={!!topics}
          generateSummary={generateSummary}
          handleChangeTemplate={handleChangeTemplate}
          selectedTemplate={selectedTemplate}
          copyAll={copyAllTopics}
          candidateId={candidateId}
          notesExpanded={notesExpanded}
          toggleNotes={toggleNotes}
        >
          {summaryLoading && (
            <Box mb={12}>
              <AiNotesLoading loadingText="Generating summary" />
            </Box>
          )}
          {!summaryLoading && (!topics || topics.length < 1) && (
            <Box
              alignItems="center"
              display="flex"
              flexDir="column"
              h="100%"
              width="100%"
              pb="4"
              gap="4"
              px="10"
            >
              <EmptyAIIcon mb="5" maxW="380px" />
              <Text align="center" maxW="380px" textAlign="center">
                No candidate debrief yet. Generating debrief notes will
                summarize all interviews for this candidate and organize them by
                competencies, when available. Try it out now.
              </Text>
              <Button
                size="sm"
                colorScheme="purple"
                bg="purple.450"
                onClick={() => {
                  if (selectedTemplate && generateSummary) {
                    generateSummary(selectedTemplate);
                  }
                }}
              >
                Generate debrief
              </Button>
              <Text maxW="380px" textAlign="center">
                This may take up to 30 seconds, depending on the number of
                interviews.
              </Text>
            </Box>
          )}
          {topics && topics.length > 0 && !summaryLoading && (
            <Flex flexDir="row" width={innerExpandedWidth} overflow="auto">
              <TopicCoverageListV2
                topics={topics}
                onNavigate={() => {
                  if (!notesExpanded) {
                    toggleNotes();
                  }
                }}
              />
              <Box mb={2} onScroll={trackFirstScroll} overflow="auto">
                <NotesV2
                  topics={topics}
                  citationMap={citationMap}
                  onClickTimestamp={(callId: string, t: number) => {
                    window.open(`/interview/${callId}?t=${t}`);
                  }}
                />
              </Box>
            </Flex>
          )}
        </SeparateTopicSummaryWrapper>
      )}
    </Flex>
  );
};

type SeparateTopicSummaryWrapperProps = {
  children: React.ReactNode;
  candidateId: string;
  selectedTemplate: string;
  handleChangeTemplate: (templateId: string) => void;
  templates?: CandidateSummaryTemplateFragment[] | null;
  summaryExists?: boolean;
  generateSummary?: (templateId: string) => void;
  copyAll: () => void;
  notesExpanded: boolean;
  toggleNotes: () => void;
};

const SeparateTopicSummaryWrapper = ({
  children,
  candidateId,
  selectedTemplate,
  handleChangeTemplate,
  templates,
  summaryExists,
  copyAll,
  notesExpanded,
  toggleNotes,
}: SeparateTopicSummaryWrapperProps): JSX.Element => {
  const currentBreakPoint = useCurrentBreakpoint();
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();
  const currentTemplate = templates?.find(
    (item) => item.id === selectedTemplate
  );
  const showEdit = useShowEditCandidateSummaryTemplate();
  return (
    <Flex
      flexDir="column"
      overflow="hidden"
      onScroll={(e) => {
        // This is to prevent horizontal scrolling that can occur when the user
        // clicks a link in the topic sidepanel. This el should never scroll.
        if (e.currentTarget.scrollLeft > 0) {
          e.currentTarget.scrollLeft = 0;
        }
      }}
      width="100%"
    >
      <Flex
        flexDir="row"
        position="relative"
        alignItems="center"
        px={6}
        zIndex="0"
      >
        <ColumnHeading
          iconType={HiOutlineClipboardList}
          title="Topic coverage"
        />

        <Button
          variant="ghost"
          onClick={() => {
            toggleNotes();
            sendGAEvent(
              `candidate_summary_notes_${
                notesExpanded ? "collapse" : "expand"
              }`,
              "candidate"
            );
          }}
          position="absolute"
          right={6}
          background="white"
          zIndex="1"
          size="sm"
          leftIcon={
            notesExpanded ? (
              <HiChevronDoubleRight size={20} />
            ) : (
              <HiChevronDoubleLeft size={20} />
            )
          }
        >
          Candidate summary
        </Button>
        <Flex direction="row" marginRight="200px">
          {summaryExists && (
            <Flex
              flexDir="row"
              alignItems="center"
              gap={2}
              marginRight={2}
              marginLeft={8}
              opacity={notesExpanded ? 1 : 0}
              transition="opacity 0.3s"
            >
              <Text
                fontSize="sm"
                color="gray.600"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                Template
              </Text>
              <Select
                size="sm"
                borderRadius="md"
                onChange={(e) => {
                  handleChangeTemplate(e.target.value);
                }}
                value={selectedTemplate}
              >
                {templates?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
              {templates &&
                !currentTemplate?.isOrgDefault &&
                currentTemplate &&
                showEdit && (
                  <Tooltip label="Edit">
                    <IconButton
                      icon={<HiOutlineCog8Tooth />}
                      fontSize="20px"
                      aria-label="edit"
                      variant="icon"
                      onClick={() =>
                        navigate(
                          `/debrief/${currentTemplate.id}/edit?candidateId=${candidateId}`
                        )
                      }
                    />
                  </Tooltip>
                )}
            </Flex>
          )}
          {summaryExists && (
            <Tooltip label="Copy to clipboard">
              <IconButton
                isDisabled={!currentTemplate}
                variant="icon"
                aria-label="Copy to clipboard"
                onClick={() => copyAll()}
                opacity={notesExpanded ? 1 : 0}
                transition="opacity 0.3s"
                icon={
                  <HiOutlineClipboardCopy
                    style={{ transform: "scaleX(-1)" }}
                    strokeWidth="1.5px"
                    size={20}
                  />
                }
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
      <ColumnHeaderBorder mx={6} />
      {!summaryExists && (
        <Flex flexDir="column" gap={1} width="100%" pb={5} px={6}>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              fontSize="sm"
              color="gray.600"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              Template
            </Text>
          </Flex>
          <Flex flexDir="row" alignItems="center" gap={2}>
            <Select
              size="sm"
              borderRadius="md"
              onChange={(e) => {
                handleChangeTemplate(e.target.value);
              }}
              value={selectedTemplate}
              width="100%"
            >
              {templates?.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            {templates &&
              !currentTemplate?.isOrgDefault &&
              currentTemplate &&
              showEdit &&
              currentBreakPoint !== "sm" && (
                <Tooltip label="Edit">
                  <IconButton
                    icon={<HiOutlineCog8Tooth />}
                    fontSize="20px"
                    aria-label="edit"
                    variant="icon"
                    onClick={() =>
                      navigate(
                        `/debrief/${currentTemplate.id}/edit?candidateId=${candidateId}`
                      )
                    }
                  />
                </Tooltip>
              )}

            {summaryExists && (
              <Tooltip label="Copy to clipboard">
                <IconButton
                  variant="icon"
                  aria-label="Copy to clipboard"
                  onClick={() => copyAll()}
                  icon={
                    <HiOutlineClipboardCopy
                      style={{ transform: "scaleX(-1)" }}
                      strokeWidth="1.5px"
                      size={20}
                    />
                  }
                />
              </Tooltip>
            )}
          </Flex>
        </Flex>
      )}
      {children}
    </Flex>
  );
};

const CombinedTopicSummaryWrapper = ({
  children,
  candidateId,
  selectedTemplate,
  handleChangeTemplate,
  templates,
  summaryExists,
  copyAll,
}: SeparateTopicSummaryWrapperProps): JSX.Element => {
  // if bigger than small
  const currentBreakPoint = useCurrentBreakpoint();
  const isBaseOrSmall = ["base", "sm"].includes(currentBreakPoint);
  const currentTemplate = templates?.find(
    (item) => item.id === selectedTemplate
  );
  const showEdit = useShowEditCandidateSummaryTemplate();
  const navigate = useNavigate();
  return (
    <Flex flexDir="column" overflow="hidden" width="100%">
      {!isBaseOrSmall && (
        <Box px={6}>
          <ColumnHeading
            iconType={HiOutlineClipboardList}
            title="Topics & Summary"
          />
          <ColumnHeaderBorder />
        </Box>
      )}
      <Flex flexDir="row" alignItems="center" px={6}>
        <Flex flexDir="column" gap={1} width="100%" pb={5}>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              fontSize="sm"
              color="gray.600"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              Template
            </Text>
          </Flex>
          <Flex flexDir="row" alignItems="center" gap={2}>
            <Select
              size="sm"
              borderRadius="md"
              onChange={(e) => {
                handleChangeTemplate(e.target.value);
              }}
              value={selectedTemplate}
              width="100%"
            >
              {templates?.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            {templates &&
              !currentTemplate?.isOrgDefault &&
              currentTemplate &&
              showEdit &&
              currentBreakPoint !== "sm" && (
                <Tooltip label="Edit">
                  <IconButton
                    icon={<HiOutlineCog8Tooth />}
                    fontSize="20px"
                    aria-label="edit"
                    variant="icon"
                    onClick={() =>
                      navigate(
                        `/debrief/${currentTemplate.id}/edit?candidateId=${candidateId}`
                      )
                    }
                  />
                </Tooltip>
              )}

            {summaryExists && (
              <Tooltip label="Copy to clipboard">
                <IconButton
                  variant="icon"
                  aria-label="Copy to clipboard"
                  onClick={() => copyAll()}
                  icon={
                    <HiOutlineClipboardCopy
                      style={{ transform: "scaleX(-1)" }}
                      strokeWidth="1.5px"
                      size={20}
                    />
                  }
                />
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Box px={6} overflow="auto">
        {children}
      </Box>
    </Flex>
  );
};

const reshapeNotes = (data: CandidateSummary): Topic[] => {
  const { notes } = data;
  const sortedNotes = [...notes].sort((a, b) => a.position - b.position);

  // Convert sorted notes into topics, subtopics, notes, and citations
  const topics: Topic[] = [];
  let currentTopic: Topic | null = null;
  let currentSubtopic: SubTopic | null = null;
  let currentNote: Note | null = null;
  sortedNotes.forEach((note) => {
    if (currentTopic === null || currentTopic.name !== note.topic) {
      currentTopic = {
        name: note.topic || "",
        subTopics: [],
      };
      topics.push(currentTopic);
    }
    if (currentSubtopic === null || currentSubtopic.name !== note.subtopic) {
      if (
        note.citations.length === 0 &&
        (note.subtopic === "Other" || note.subtopic?.trim() === "")
      ) {
        return;
      }
      if (note.subtopic === "" && note.citations.length === 0) {
        return;
      }
      currentSubtopic = {
        name: note.subtopic || "",
        notes: [],
      };
      currentTopic.subTopics.push(currentSubtopic);
    }
    currentNote = {
      id: note.id,
      text: note.text,
      citations: note.citations.map((citation) => ({
        id: citation.id,
        callId: citation.callId,
        time: citation.time,
      })),
    };
    currentSubtopic.notes.push(currentNote);
  });

  return topics;
};

export default SummaryTabContentV2;
