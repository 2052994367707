import { Box, Center, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Navigate } from "react-router-dom";

import { LoadingIndicator } from "../../../../components";
import { formatDatetime } from "../../../../utils/datetime";
import { canViewAdvancedInsights } from "../../../../utils/permissions";
import { SkillReportQuery } from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import AnalyticsBarChart, {
  AnalyticsBarChartData,
} from "../bar-chart/AnalyticsBarChart";
import AnalyticsSkillsReportTable from "./AnalyticsSkillsReportTable";
import SkillsReportHeader from "./SkillsReportHeader";

type AnalyticsSkillsReportResultsProps = {
  data: SkillReportQuery | undefined;
  generating: boolean;
  startBuildReport: () => void;
};

const AnalyticsSkillsReportResults: React.FC<
  AnalyticsSkillsReportResultsProps
> = ({ data, generating, startBuildReport }) => {
  const currentUser = useCurrentUser();

  if (!canViewAdvancedInsights(currentUser)) {
    return <Navigate to="/" replace />;
  }

  const report = data?.skillReport?.skillReport;

  const displayDateRangeValue = report
    ? `${formatDatetime(report.dateRangeStart, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })} - ${formatDatetime(report.dateRangeEnd, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}`
    : " ";

  const reportCandidates = data?.skillReport?.candidates || [];
  const skillNamesList = report?.skills.map((s) => s.name) || [];

  const tableData = reportCandidates.map((candidate) => {
    const skills = skillNamesList.map((skillName) => {
      const skill = candidate.skills.find((s) => s.name === skillName);
      return {
        name: skillName,
        covered: skill?.covered || false,
      };
    });

    return {
      id: candidate.candidateId,
      candidateName: candidate.candidateName,
      numInterviews: candidate.numInterviews,
      skills,
    };
  });

  const candidateCount = reportCandidates.length;
  const totalInterviewCount = reportCandidates.reduce(
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    (acc, candidate) => acc + candidate.numInterviews,
    0
  );

  const reportPosition =
    report?.positions && report.positions.length > 0
      ? report.positions[0]
      : null;
  const positionDisplayTitle =
    reportPosition?.displayTitle || "No position title";
  const subHeader = `${candidateCount} candidates • ${totalInterviewCount} interviews • ${positionDisplayTitle}`;

  const chartCounts: AnalyticsBarChartData = skillNamesList
    .map((skillName, i) => {
      const count: number = reportCandidates.filter((candidate) =>
        candidate.skills.find((s) => s.name === skillName && s.covered)
      ).length;

      return {
        key: `skill-${i}-${skillName}`,
        label: skillName,
        value: (count / candidateCount) * 100,
      };
    })
    .sort((a, b) => b.value - a.value);

  return (
    <Box pb="100px">
      <SkillsReportHeader startBuildReport={startBuildReport} includeDetails />
      <Flex
        flexDir="row"
        px={8}
        py={4}
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Text as="span" fontWeight="500">
          {subHeader}
        </Text>
      </Flex>
      <Flex flexDir="column" p={8} pt={12}>
        <VStack spacing={6}>
          <Flex direction="column" alignSelf="start">
            <Text fontSize="3xl" fontWeight={400}>
              How consistently are key interview topics being covered?
            </Text>
            <Text fontWeight={400} color="gray.400">
              Identify which topics are discussed during interviews across all
              candidates for one or more positions.
            </Text>
          </Flex>
          <ReportSection>
            <ReportSectionHeader
              header="How often a skill was discussed across candidates’ interviews"
              date={displayDateRangeValue}
              subheader={subHeader}
            />
            <Box w="100%" py="6">
              {generating && <LoadingIndicator />}
              {!generating && tableData.length === 0 && (
                <Center>
                  <Text fontSize="sm" color="gray.500">
                    No data available for this position and date range. Try
                    generating a new report with different settings.
                  </Text>
                </Center>
              )}
              {!generating && tableData.length > 0 && (
                <AnalyticsBarChart
                  data={chartCounts}
                  margin={{ left: 180 }}
                  multiColor
                />
              )}
            </Box>
          </ReportSection>
          <ReportSection>
            <ReportSectionHeader
              header="How often a skill was discussed across candidates’ interviews"
              date={displayDateRangeValue}
              subheader={subHeader}
            />
            <Box mt="3" width="100%">
              {generating && <AnalyticsReportTableSkeleton />}
              {!generating && tableData.length === 0 && (
                <Center py="6">
                  <Text fontSize="sm" color="gray.500">
                    No data available for this position and date range. Try
                    generating a new report with different settings.
                  </Text>
                </Center>
              )}
              {!generating && tableData.length > 0 && (
                <AnalyticsSkillsReportTable
                  tableData={tableData}
                  skills={skillNamesList}
                />
              )}
            </Box>
          </ReportSection>
        </VStack>
      </Flex>
    </Box>
  );
};

const ReportSection: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <VStack
      spacing={6}
      w="100%"
      p={6}
      pt={5}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="xl"
    >
      {children}
    </VStack>
  );
};

const ReportSectionHeader: React.FC<{
  header: string;
  date: string;
  subheader: string;
}> = ({ header, date, subheader }) => {
  return (
    <Flex w="100%" direction="column">
      <Flex w="100%" direction="row" align="center" justify="space-between">
        <Text fontSize="lg" fontWeight={600}>
          {header}
        </Text>
        <Text fontSize="sm" fontWeight={400} color="gray.400" ml={2}>
          {date}
        </Text>
      </Flex>
      <Text fontSize="sm" fontWeight={400} color="gray.600">
        {subheader}
      </Text>
    </Flex>
  );
};

export default AnalyticsSkillsReportResults;
