import { Modal, ModalOverlay } from "@chakra-ui/react";
import React from "react";

import { useToast } from "../../../components";
import {
  CallGuideFragment,
  CallGuideListItemFragment,
  CallGuideQuery,
} from "../../graphql";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";
import GuideForm from "../GuideForm/GuideForm";

type CallGuide = NonNullable<CallGuideQuery["callGuide"]>;

export interface FormData {
  name: string;
  positionId?: string;
  interviewStageIds?: string[];
  assignedUserIds?: string[];
}

interface EditCallGuideModalProps {
  callGuide: CallGuide | CallGuideFragment | CallGuideListItemFragment;
  isOpen: boolean;
  onClose: () => void;
}

const EditCallGuideModal: React.FC<EditCallGuideModalProps> = ({
  callGuide,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const [updateCallGuide, { error, loading }] = useUpdateCallGuide({
    onError: (e) => toast({ status: "error", description: e.message }),
  });

  const onSubmit = ({
    name,
    positionId,
    interviewStageId,
    assignedUserIds,
  }: {
    name: string;
    positionId?: string;
    interviewStageId?: string;
    assignedUserIds?: string[];
  }): void => {
    updateCallGuide({
      variables: {
        id: callGuide.id,
        name,
        positionId,
        interviewStageId,
        assignedUserIds,
      },
    });
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay>
        <GuideForm
          title="Edit Guide"
          name={callGuide.name ?? ""}
          positionId={callGuide.position?.id}
          interviewStageId={callGuide.interviewStage?.id}
          assignedUserIds={callGuide.assignedUsers?.map((user) => user?.userId)}
          error={error}
          isLoading={loading}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </ModalOverlay>
    </Modal>
  );
};

export default EditCallGuideModal;
