import { Flex, Tag, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Select from "react-select";

import { errorToast, useToast } from "../../../../../components";
import useDebounce from "../../../../../hooks/useDebounce";
import { formatISODate } from "../../../../../utils/datetime";
import { useOrgReportPositionOptionsQuery } from "../../../../graphql";
import { useAnalyticsSelectTheme } from "../../useAnalyticsSelectTheme";
import { DateRangeState } from "./types";

type PositionOption = {
  id: string;
  displayTitle: string;
  requisitionId?: string | null;
  atsStatus?: string | null;
};

type SkillsReportPositionSelectProps = {
  value?: string;
  onSelect: (newValue: { value: string; label: string }) => void;
  dateRange: DateRangeState;
};

const SkillsReportPositionSelect: React.FC<SkillsReportPositionSelectProps> = ({
  value,
  onSelect,
  dateRange,
}) => {
  const toast = useToast();
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();

  const [positionSearchTerm, setPositionSearchTerm] = useState("");
  const debouncedPositionSearchTerm = useDebounce(positionSearchTerm, 250);
  const { data: positionsData, loading: positionsLoading } =
    useOrgReportPositionOptionsQuery({
      variables: {
        pagination: {
          page: 1,
          limit: 50,
          orderBy: "lastCallAt desc",
          searchTerm: debouncedPositionSearchTerm,
        },
        activeOnly: false,
        dateRangeStart: formatISODate(dateRange.start),
        dateRangeEnd: formatISODate(dateRange.end),
      },
      onError: () => errorToast(toast, "Error loading positions"),
    });
  // We may want to group positions by ATS status or department in future
  // https://react-select.com/home#getting-started
  const positions =
    positionsData?.currentUser?.organization.positions.results ?? [];

  const {
    container: containerStyles = {},
    menu: menuStyles = {},
    ...selectStylesRest
  } = selectStyles;
  const commonSelectStyles = {
    container: (provided: Record<string, any>) => ({
      ...provided,
      flex: 1,
      minWidth: 120,
      ...containerStyles,
    }),
    menu: (provided: Record<string, any>) => ({
      ...provided,
      width: 600,
      ...menuStyles,
    }),
    ...selectStylesRest,
  };

  return (
    <Select
      {...selectTheme}
      styles={commonSelectStyles}
      placeholder="Select or search for position"
      onInputChange={(newValue) => setPositionSearchTerm(newValue)}
      aria-label="position-select"
      isClearable
      options={positions}
      isLoading={positionsLoading}
      value={positions.find((position) => position.id === value)}
      noOptionsMessage={() => "No positions found"}
      getOptionLabel={(position: PositionOption) => position.displayTitle}
      getOptionValue={(position: PositionOption) => position.id}
      onChange={(selected) => {
        onSelect({
          value: selected?.id || "",
          label: selected?.displayTitle || "",
        });
      }}
      formatOptionLabel={(option) => (
        <div>
          <Flex w="100%" align="flex-start" justify="space-between">
            <div>
              {option.displayTitle}
              {option.requisitionId && (
                <>
                  <Text as="span" color="gray.300">
                    {" "}
                    |{" "}
                  </Text>
                  <Text as="span" color="gray.500">
                    Req: {option.requisitionId}
                  </Text>
                </>
              )}
            </div>
            {option.atsStatus && (
              <Tag size="sm" textTransform="capitalize" flexShrink="0" ml="3">
                {option.atsStatus}
              </Tag>
            )}
          </Flex>
        </div>
      )}
    />
  );
};

export default SkillsReportPositionSelect;
