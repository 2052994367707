import { Box, BoxProps, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

export const ColumnHeaderBorder: React.FC<BoxProps> = ({ ...styles }) => {
  return (
    <Box
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
      mb={6}
      mt={3}
      {...styles}
    />
  );
};

export const ColumnHeading: React.FC<{
  iconType: IconType;
  title: string;
}> = ({ iconType, title }) => {
  return (
    <Flex
      flexDir="row"
      verticalAlign="baseline"
      py={2}
      fontSize="md"
      color="gray.800"
      fontWeight="600"
      flexShrink={0}
    >
      <Icon as={iconType} h={6} w={6} mr={1} />
      {title}
    </Flex>
  );
};
