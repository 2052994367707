import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Alert } from "../../../../components";
import { useSkillReportQuery } from "../../../graphql";
import AnalyticsSkillsReportResults from "./AnalyticsSkillsReportResults";

const AnalyticsSkillsReport: React.FC = () => {
  // The router handles the case where the reportId is not present
  const { reportId } = useParams() as { reportId: string };

  const { loading, error, data } = useSkillReportQuery({
    variables: {
      id: reportId,
    },
  });

  const navigate = useNavigate();

  // TODO: Once the report is generated as a background task,
  // we should show a loading spinner when query is loading and a
  // generating state when the report is being generated.
  // For now, generating and loading are the same.
  const generating = loading;

  if (error) {
    return <Alert status="error" description="Error loading report" reload />;
  }

  return (
    <AnalyticsSkillsReportResults
      data={data}
      generating={generating}
      startBuildReport={() => navigate("/analytics/skill-report")}
    />
  );
};

export default AnalyticsSkillsReport;
