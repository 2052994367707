import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
};

export type AddPlanInterview = {
  __typename?: "AddPlanInterview";
  planInterview?: Maybe<PlanInterview>;
};

export type AddPlanQuestion = {
  __typename?: "AddPlanQuestion";
  question?: Maybe<PlanQuestion>;
};

export type AddPlanQuestionGroup = {
  __typename?: "AddPlanQuestionGroup";
  questionGroup?: Maybe<PlanQuestionGroup>;
};

export type Annotation = {
  __typename?: "Annotation";
  category: Scalars["String"];
  end: Scalars["Int"];
  problem: Scalars["String"];
  start: Scalars["Int"];
  suggestions?: Maybe<Array<Scalars["String"]>>;
  text: Scalars["String"];
  value?: Maybe<Scalars["Float"]>;
};

export type AnnotationConfig = {
  __typename?: "AnnotationConfig";
  category: Scalars["String"];
  color: Scalars["String"];
  description: Scalars["String"];
  priority: Scalars["Int"];
  style: AnnotationTypeEnum;
};

export enum AnnotationTypeEnum {
  Pill = "PILL",
  Underline = "UNDERLINE",
}

export type BestPractice = {
  __typename?: "BestPractice";
  key: Scalars["String"];
  text: Scalars["String"];
  value: Scalars["Boolean"];
};

export enum CallType {
  Browser = "BROWSER",
  GoogleMeet = "GOOGLE_MEET",
  Incoming = "INCOMING",
  InPersonAudio = "IN_PERSON_AUDIO",
  Outgoing = "OUTGOING",
  TeamsNotetaker = "TEAMS_NOTETAKER",
  Zoom = "ZOOM",
  ZoomNotetaker = "ZOOM_NOTETAKER",
}

export enum CallVisibility {
  Organization = "ORGANIZATION",
  Private = "PRIVATE",
  Public = "PUBLIC",
  Restricted = "RESTRICTED",
}

export type CreateJobDescription = {
  __typename?: "CreateJobDescription";
  jobDescription: JobDescription;
};

export type CreatePlan = {
  __typename?: "CreatePlan";
  planId: Scalars["ID"];
};

export type DeleteJobDescription = {
  __typename?: "DeleteJobDescription";
  success: Scalars["Boolean"];
};

export type DeletePlanInterview = {
  __typename?: "DeletePlanInterview";
  success: Scalars["Boolean"];
};

export type DeletePlanQuestion = {
  __typename?: "DeletePlanQuestion";
  success: Scalars["Boolean"];
};

export type DeletePlanQuestionGroup = {
  __typename?: "DeletePlanQuestionGroup";
  success: Scalars["Boolean"];
};

/** Features that only certain organizations should have access to. */
export enum FeatureName {
  AdvancedInsights = "ADVANCED_INSIGHTS",
  AtsUserLevelImport = "ATS_USER_LEVEL_IMPORT",
  AutoInviteInterviewers = "AUTO_INVITE_INTERVIEWERS",
  Bullhorn = "BULLHORN",
  CandidateOptIn = "CANDIDATE_OPT_IN",
  CandidateOptOut = "CANDIDATE_OPT_OUT",
  ComplianceCheck = "COMPLIANCE_CHECK",
  DataRetention = "DATA_RETENTION",
  Eeoc = "EEOC",
  EmailAuth = "EMAIL_AUTH",
  GoogleAuth = "GOOGLE_AUTH",
  GoogleMeet = "GOOGLE_MEET",
  Greenhouse = "GREENHOUSE",
  Icims = "ICIMS",
  Insights = "INSIGHTS",
  InternationalCalling = "INTERNATIONAL_CALLING",
  InterviewerOptOut = "INTERVIEWER_OPT_OUT",
  InPersonInterviews = "IN_PERSON_INTERVIEWS",
  JoinByPosition = "JOIN_BY_POSITION",
  Lever = "LEVER",
  LlmCallMetrics = "LLM_CALL_METRICS",
  ManualScheduling = "MANUAL_SCHEDULING",
  Merge = "MERGE",
  MsftAuth = "MSFT_AUTH",
  MsTeams = "MS_TEAMS",
  MultiLanguageTranscription = "MULTI_LANGUAGE_TRANSCRIPTION",
  OktaAuth = "OKTA_AUTH",
  PhoneAuth = "PHONE_AUTH",
  Plan = "PLAN",
  RecordingDisclaimer = "RECORDING_DISCLAIMER",
  RestApi = "REST_API",
  Scoring = "SCORING",
  SelfSignUp = "SELF_SIGN_UP",
  Sense = "SENSE",
  Smartrecruiters = "SMARTRECRUITERS",
  Training = "TRAINING",
  VirtualOnsiteSplitting = "VIRTUAL_ONSITE_SPLITTING",
  Zoom = "ZOOM",
  ZoomNative = "ZOOM_NATIVE",
}

export enum FeatureSettingLevel {
  Default = "DEFAULT",
  Group = "GROUP",
  Organization = "ORGANIZATION",
}

export type GenerateAboutRole = {
  __typename?: "GenerateAboutRole";
  aboutRole: Scalars["String"];
};

export type GeneratePlanQuestions = {
  __typename?: "GeneratePlanQuestions";
  jobDescription: JobDescription;
};

export type GenerateRequirementAreaText = {
  __typename?: "GenerateRequirementAreaText";
  requirementAreaText: Scalars["String"];
};

export type GenerateRequirementAreas = {
  __typename?: "GenerateRequirementAreas";
  requirementAreas: Array<Scalars["String"]>;
};

export type GenerateRequirements = {
  __typename?: "GenerateRequirements";
  requirements: Array<Scalars["String"]>;
};

export type GenerateResponsibilities = {
  __typename?: "GenerateResponsibilities";
  responsibilities: Array<Scalars["String"]>;
};

export type GenerateSimplifiedText = {
  __typename?: "GenerateSimplifiedText";
  isComplex: Scalars["Boolean"];
  problem?: Maybe<Scalars["String"]>;
  simplifiedText: Scalars["String"];
};

export enum InstallZoomNotetakerAppJobProcessingStatus {
  Completed = "COMPLETED",
  Dispatched = "DISPATCHED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export enum InternalUserRoleName {
  BhAdminBasic = "BH_ADMIN_BASIC",
  BhAdminSuper = "BH_ADMIN_SUPER",
  BhAdminUser = "BH_ADMIN_USER",
  BhAdminUserOutsideUs = "BH_ADMIN_USER_OUTSIDE_US",
}

export type JobDescription = {
  __typename?: "JobDescription";
  bestPractices?: Maybe<Scalars["JSONString"]>;
  createdAt: Scalars["DateTime"];
  creator: User;
  creatorId?: Maybe<Scalars["UUID"]>;
  creatorSession?: Maybe<JobDescriptionCreatorSession>;
  customFooterSections: Array<JobDescriptionTemplateCustomSection>;
  customHeaderSections: Array<JobDescriptionTemplateCustomSection>;
  editorContent?: Maybe<Scalars["JSONString"]>;
  error?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  initialNumBiasedTerms?: Maybe<Scalars["Int"]>;
  initialReadabilityScore?: Maybe<Scalars["String"]>;
  initialReadingTime?: Maybe<Scalars["String"]>;
  initialScore?: Maybe<Scalars["Int"]>;
  internalShares: Array<JobDescriptionShare>;
  jobDescriptionState?: Maybe<JobDescriptionState>;
  lastModifiedAt?: Maybe<Scalars["DateTime"]>;
  numBiasedTerms?: Maybe<Scalars["Int"]>;
  numWords?: Maybe<Scalars["Int"]>;
  organizationId: Scalars["UUID"];
  plan?: Maybe<Plan>;
  questionGroups?: Maybe<Array<PlanQuestionGroup>>;
  questionsProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  questionsProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  questionsProcessingStatus?: Maybe<PlanQuestionsProcessingStatus>;
  readabilityScore?: Maybe<Scalars["String"]>;
  readabilityScoreRaw?: Maybe<Scalars["Int"]>;
  readingTime?: Maybe<Scalars["String"]>;
  readingTimeMinutesMax?: Maybe<Scalars["Int"]>;
  readingTimeMinutesMin?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
  shareableUsers: Array<User>;
  template?: Maybe<JobDescriptionTemplate>;
  templateAppliedAt?: Maybe<Scalars["DateTime"]>;
  templateId?: Maybe<Scalars["UUID"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  visibleTo: PaginatedJobDescriptionVisibleTo;
  whitelistedWords: Array<Maybe<Scalars["String"]>>;
};

export type JobDescriptionVisibleToArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type JobDescriptionAnalysis = {
  __typename?: "JobDescriptionAnalysis";
  annotationConfigs: Array<AnnotationConfig>;
  annotations: Array<Annotation>;
  bestPractices: Array<BestPractice>;
  error?: Maybe<Scalars["String"]>;
  numWords: Scalars["Int"];
  readabilityScore: Scalars["String"];
  readabilityScoreRaw: Scalars["Float"];
  readingTime: Scalars["String"];
  readingTimeMinutesMax: Scalars["Int"];
  readingTimeMinutesMin: Scalars["Int"];
  score: Scalars["Int"];
};

export type JobDescriptionCreatorSession = {
  __typename?: "JobDescriptionCreatorSession";
  aboutRoleTone?: Maybe<PlanJobDescriptionTone>;
  aboutSectionCandidatePitch?: Maybe<Scalars["String"]>;
  aboutSectionJobQualities?: Maybe<Scalars["String"]>;
  aboutSectionText?: Maybe<Scalars["String"]>;
  aboutSectionTitle?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  creatorId: Scalars["String"];
  eeocStatement?: Maybe<Scalars["String"]>;
  eeocStatementTitle?: Maybe<Scalars["String"]>;
  htmlOutput?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  jobDescriptionId: Scalars["String"];
  jobTitle?: Maybe<Scalars["String"]>;
  jobTitleTitle?: Maybe<Scalars["String"]>;
  locationName?: Maybe<Scalars["String"]>;
  locationTitle?: Maybe<Scalars["String"]>;
  locationType?: Maybe<Scalars["String"]>;
  organization: Organization;
  organizationId: Scalars["String"];
  requirementsSectionInputs?: Maybe<Scalars["JSONString"]>;
  requirementsSectionListItems: Array<Scalars["String"]>;
  requirementsSectionText?: Maybe<Scalars["String"]>;
  requirementsSectionTitle?: Maybe<Scalars["String"]>;
  responsibilitiesSectionInputs?: Maybe<Scalars["JSONString"]>;
  responsibilitiesSectionListItems: Array<Scalars["String"]>;
  responsibilitiesSectionText?: Maybe<Scalars["String"]>;
  responsibilitiesSectionTitle?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type JobDescriptionListItem = {
  __typename?: "JobDescriptionListItem";
  createdAt: Scalars["DateTime"];
  creator: User;
  error?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  jobDescriptionState?: Maybe<JobDescriptionState>;
  plan?: Maybe<Plan>;
  questionsProcessingStatus?: Maybe<PlanQuestionsProcessingStatus>;
  score?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type JobDescriptionShare = {
  __typename?: "JobDescriptionShare";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  jobDescriptionId: Scalars["UUID"];
  message?: Maybe<Scalars["String"]>;
  sharedBy: User;
  sharedById: Scalars["UUID"];
  sharedTo: User;
  sharedToId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
};

export enum JobDescriptionState {
  Creator = "CREATOR",
  Optimizer = "OPTIMIZER",
}

export type JobDescriptionTemplate = {
  __typename?: "JobDescriptionTemplate";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  sections: Array<JobDescriptionTemplateSection>;
  updatedAt: Scalars["DateTime"];
};

export type JobDescriptionTemplateCustomSection = {
  __typename?: "JobDescriptionTemplateCustomSection";
  header: Scalars["String"];
  order: Scalars["Int"];
  text: Scalars["String"];
};

export type JobDescriptionTemplateSection = {
  __typename?: "JobDescriptionTemplateSection";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  jobDescriptionTemplateId: Scalars["UUID"];
  order: Scalars["Float"];
  organizationId: Scalars["UUID"];
  sectionHeader: Scalars["String"];
  sectionText: Scalars["String"];
  type: JobDescriptionTemplateSectionType;
  updatedAt: Scalars["DateTime"];
};

export enum JobDescriptionTemplateSectionType {
  AboutRole = "ABOUT_ROLE",
  Custom = "CUSTOM",
  Requirements = "REQUIREMENTS",
  Responsibilities = "RESPONSIBILITIES",
}

export type JobDescriptionVisibleTo = {
  __typename?: "JobDescriptionVisibleTo";
  id: Scalars["ID"];
  role: Scalars["String"];
  user: User;
};

export enum JobRequirementTopic {
  Accomplishments = "ACCOMPLISHMENTS",
  ExperienceAndEducation = "EXPERIENCE_AND_EDUCATION",
  SpecificSkills = "SPECIFIC_SKILLS",
}

export type Membership = {
  __typename?: "Membership";
  atsControlled: Scalars["Boolean"];
  clientMembershipControlled: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  grantedById?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  position: Position;
  positionId: Scalars["UUID"];
  role: PositionRole;
  roleId: Scalars["UUID"];
  updatedAt: Scalars["DateTime"];
  user: User;
  userId: Scalars["UUID"];
};

export type MoveQuestionGroupToInterview = {
  __typename?: "MoveQuestionGroupToInterview";
  plan?: Maybe<Plan>;
};

export type Mutation = {
  __typename?: "Mutation";
  addPlanInterview?: Maybe<AddPlanInterview>;
  addPlanQuestion?: Maybe<AddPlanQuestion>;
  addPlanQuestionGroup?: Maybe<AddPlanQuestionGroup>;
  createJobDescription?: Maybe<CreateJobDescription>;
  createPlan?: Maybe<CreatePlan>;
  deleteJobDescription?: Maybe<DeleteJobDescription>;
  deletePlanInterview?: Maybe<DeletePlanInterview>;
  deletePlanQuestion?: Maybe<DeletePlanQuestion>;
  deletePlanQuestionGroup?: Maybe<DeletePlanQuestionGroup>;
  generateAboutRole?: Maybe<GenerateAboutRole>;
  generatePlanQuestions?: Maybe<GeneratePlanQuestions>;
  generateRequirementAreaText?: Maybe<GenerateRequirementAreaText>;
  generateRequirementAreas?: Maybe<GenerateRequirementAreas>;
  generateRequirements?: Maybe<GenerateRequirements>;
  generateResponsibilities?: Maybe<GenerateResponsibilities>;
  generateSimplifiedText?: Maybe<GenerateSimplifiedText>;
  moveQuestionGroupToInterview?: Maybe<MoveQuestionGroupToInterview>;
  removeJobDescriptionShare?: Maybe<RemoveJobDescriptionShare>;
  reorderPlanInterviews?: Maybe<ReorderPlanInterviews>;
  reorderPlanQuestionGroups?: Maybe<ReorderPlanQuestionGroups>;
  reorderPlanQuestions?: Maybe<ReorderPlanQuestions>;
  /** User is requesting to upgrade their organization to plan */
  requestPlanUpgrade?: Maybe<RequestPlanUpgrade>;
  shareJobDescription?: Maybe<ShareJobDescription>;
  signOut?: Maybe<SignOut>;
  unimpersonate?: Maybe<Unimpersonate>;
  updateCurrentUserBrowserExtensionVersion?: Maybe<UpdateCurrentUserBrowserExtensionVersion>;
  updateJobDescription?: Maybe<UpdateJobDescription>;
  updateJobDescriptionCreatorSession?: Maybe<UpdateJobDescriptionCreatorSession>;
  updatePlanInterview?: Maybe<UpdatePlanInterview>;
  updatePlanQuestion?: Maybe<UpdatePlanQuestion>;
  updatePlanQuestionGroup?: Maybe<UpdatePlanQuestionGroup>;
};

export type MutationAddPlanInterviewArgs = {
  name: Scalars["String"];
  order: Scalars["Int"];
  planId: Scalars["ID"];
};

export type MutationAddPlanQuestionArgs = {
  groupId: Scalars["ID"];
  order: Scalars["Int"];
  planId: Scalars["ID"];
  text: Scalars["String"];
};

export type MutationAddPlanQuestionGroupArgs = {
  competency: Scalars["String"];
  interviewId: Scalars["ID"];
  order: Scalars["Int"];
  planId: Scalars["ID"];
};

export type MutationCreateJobDescriptionArgs = {
  jobDescriptionState: JobDescriptionState;
  title: Scalars["String"];
};

export type MutationCreatePlanArgs = {
  additionalAreas?: InputMaybe<Scalars["String"]>;
  includeRecruiterScreen: Scalars["Boolean"];
  jobDescriptionId: Scalars["ID"];
  jobDescriptionText: Scalars["String"];
  numberOfInterviews: Scalars["Int"];
};

export type MutationDeleteJobDescriptionArgs = {
  id: Scalars["ID"];
};

export type MutationDeletePlanInterviewArgs = {
  id: Scalars["ID"];
};

export type MutationDeletePlanQuestionArgs = {
  id: Scalars["ID"];
};

export type MutationDeletePlanQuestionGroupArgs = {
  id: Scalars["ID"];
};

export type MutationGenerateAboutRoleArgs = {
  customTone?: InputMaybe<PlanJobDescriptionTone>;
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  pitch: Scalars["String"];
  uniqueAspects: Scalars["String"];
};

export type MutationGeneratePlanQuestionsArgs = {
  blocks: Array<TextBlock>;
  id: Scalars["ID"];
  regenerate?: InputMaybe<Scalars["Boolean"]>;
  text: Scalars["String"];
};

export type MutationGenerateRequirementAreaTextArgs = {
  aboutRole: Scalars["String"];
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  responsibilities: Array<Scalars["String"]>;
  responsibilityArea: Scalars["String"];
};

export type MutationGenerateRequirementAreasArgs = {
  aboutRole: Scalars["String"];
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
};

export type MutationGenerateRequirementsArgs = {
  aboutRole: Scalars["String"];
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  partialRequirements: Array<Scalars["String"]>;
  responsibilities: Array<Scalars["String"]>;
  topic: JobRequirementTopic;
};

export type MutationGenerateResponsibilitiesArgs = {
  aboutRole: Scalars["String"];
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  priorSummaries: Array<Scalars["String"]>;
  responsibilityArea: Scalars["String"];
  upcomingResponsibilityAreas: Array<Scalars["String"]>;
};

export type MutationGenerateSimplifiedTextArgs = {
  id: Scalars["ID"];
  jobDescriptionText: Scalars["String"];
  text: Scalars["String"];
};

export type MutationMoveQuestionGroupToInterviewArgs = {
  destinationInterviewId: Scalars["ID"];
  destinationQuestionGroupIds: Array<InputMaybe<Scalars["ID"]>>;
  planId: Scalars["ID"];
  questionGroupId: Scalars["ID"];
  sourceInterviewId: Scalars["ID"];
};

export type MutationRemoveJobDescriptionShareArgs = {
  id: Scalars["ID"];
};

export type MutationReorderPlanInterviewsArgs = {
  interviewIds: Array<InputMaybe<Scalars["ID"]>>;
  planId: Scalars["ID"];
};

export type MutationReorderPlanQuestionGroupsArgs = {
  interviewId: Scalars["ID"];
  planId: Scalars["ID"];
  questionGroupIds: Array<InputMaybe<Scalars["ID"]>>;
};

export type MutationReorderPlanQuestionsArgs = {
  groupId: Scalars["ID"];
  planId: Scalars["ID"];
  questionIds: Array<InputMaybe<Scalars["ID"]>>;
};

export type MutationShareJobDescriptionArgs = {
  jobDescriptionId: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  shareToUserIds: Array<Scalars["ID"]>;
};

export type MutationUpdateCurrentUserBrowserExtensionVersionArgs = {
  browserExtensionVersion?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateJobDescriptionArgs = {
  id: Scalars["ID"];
  jobDescriptionState?: InputMaybe<JobDescriptionState>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateJobDescriptionCreatorSessionArgs = {
  aboutRoleTone?: InputMaybe<PlanJobDescriptionTone>;
  aboutSectionCandidatePitch?: InputMaybe<Scalars["String"]>;
  aboutSectionJobQualities?: InputMaybe<Scalars["String"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]>;
  aboutSectionTitle?: InputMaybe<Scalars["String"]>;
  eeocStatement?: InputMaybe<Scalars["String"]>;
  eeocStatementTitle?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  jobTitle?: InputMaybe<Scalars["String"]>;
  jobTitleTitle?: InputMaybe<Scalars["String"]>;
  locationName?: InputMaybe<Scalars["String"]>;
  locationTitle?: InputMaybe<Scalars["String"]>;
  locationType?: InputMaybe<Scalars["String"]>;
  requirementsSectionInputs?: InputMaybe<Scalars["JSONString"]>;
  requirementsSectionListItems?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  requirementsSectionText?: InputMaybe<Scalars["String"]>;
  requirementsSectionTitle?: InputMaybe<Scalars["String"]>;
  responsibilitiesSectionInputs?: InputMaybe<Scalars["JSONString"]>;
  responsibilitiesSectionListItems?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  responsibilitiesSectionText?: InputMaybe<Scalars["String"]>;
  responsibilitiesSectionTitle?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdatePlanInterviewArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationUpdatePlanQuestionArgs = {
  id: Scalars["ID"];
  questionText: Scalars["String"];
};

export type MutationUpdatePlanQuestionGroupArgs = {
  competency: Scalars["String"];
  id: Scalars["ID"];
};

export enum NotetakerRecorderDisclaimerType {
  Default = "DEFAULT",
  Native = "NATIVE",
  None = "NONE",
  Organization = "ORGANIZATION",
}

export type OffsetPaginationInfo = {
  __typename?: "OffsetPaginationInfo";
  totalPages?: Maybe<Scalars["Int"]>;
  totalRows?: Maybe<Scalars["Int"]>;
};

export type Organization = {
  __typename?: "Organization";
  allowAiCustomTemplates: Scalars["Boolean"];
  allowAiNotes: Scalars["Boolean"];
  allowCandidateOptOut: Scalars["Boolean"];
  allowEmailSignIn: Scalars["Boolean"];
  allowInPersonMeetings: Scalars["Boolean"];
  allowInterviewerOptOut: Scalars["Boolean"];
  allowManualScheduledInterviews: Scalars["Boolean"];
  allowOauthSignIn: Scalars["Boolean"];
  allowPhoneSignIn: Scalars["Boolean"];
  allowPlanJobDescriptionToneOverride: Scalars["Boolean"];
  allowSelfSignUp: Scalars["Boolean"];
  ashbyHiringManagerRoleId?: Maybe<Scalars["UUID"]>;
  ashbyRecruiterCoordinatorRoleId?: Maybe<Scalars["UUID"]>;
  ashbyRecruiterRoleId?: Maybe<Scalars["UUID"]>;
  ashbySourcerRoleId?: Maybe<Scalars["UUID"]>;
  assignGuidesToInterviewersEnabled: Scalars["Boolean"];
  atsOptInEnabled: Scalars["Boolean"];
  audioOnlyViewing: Scalars["Boolean"];
  autoJoinAllNewPositions: Scalars["Boolean"];
  automaticallyDeleteZoomRecordings: Scalars["Boolean"];
  callDisclaimerRecordingFilename?: Maybe<Scalars["String"]>;
  callDisclaimerText?: Maybe<Scalars["String"]>;
  callNameFieldIsDropdown: Scalars["Boolean"];
  competencyRatingDescriptions: Scalars["JSONString"];
  connectCallPrompt?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  dataRedactionEnabled: Scalars["Boolean"];
  dataRedactionPredictionEnabled: Scalars["Boolean"];
  defaultCandidateSummaryTemplateId?: Maybe<Scalars["UUID"]>;
  defaultHiringTeamRoleId?: Maybe<Scalars["UUID"]>;
  defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
  defaultPositionCreateRoleId?: Maybe<Scalars["UUID"]>;
  defaultRecruiterCallVisibility: CallVisibility;
  defaultScheduledInterviewerInviteRoleId?: Maybe<Scalars["UUID"]>;
  defaultSelfSignUpRoleId?: Maybe<Scalars["UUID"]>;
  deprecatedPhoneNumber?: Maybe<Scalars["String"]>;
  enableAtsRbacSync: Scalars["Boolean"];
  enableRedactionForAshby: Scalars["Boolean"];
  enableRedactionForGreenhouse: Scalars["Boolean"];
  enableRedactionForLever: Scalars["Boolean"];
  enableRedactionForSmartrecruiters: Scalars["Boolean"];
  enableRetentionSinceCandidateHired: Scalars["Boolean"];
  enableRetentionSinceCandidateRejected: Scalars["Boolean"];
  enableRetentionSinceInterview: Scalars["Boolean"];
  enableRetentionSinceLastInterview: Scalars["Boolean"];
  externalSessionDuration: Scalars["Float"];
  externalShareDefaultDurationDays: Scalars["Float"];
  featureAccess?: Maybe<Array<OrganizationFeatureAccess>>;
  freemium: Scalars["Boolean"];
  freemiumSeatCountOverride?: Maybe<Scalars["Int"]>;
  genderLabelFemale?: Maybe<Scalars["String"]>;
  genderLabelMale?: Maybe<Scalars["String"]>;
  googleMeetDisplayImageFilename?: Maybe<Scalars["String"]>;
  googleMeetDisplayName?: Maybe<Scalars["String"]>;
  googleMeetEnabled: Scalars["Boolean"];
  googleMeetRecordingDisclaimer?: Maybe<Scalars["String"]>;
  googleMeetRecordingDisclaimerType: NotetakerRecorderDisclaimerType;
  greenhouseConsentCustomField?: Maybe<Scalars["String"]>;
  greenhouseCoordinatorRoleId?: Maybe<Scalars["UUID"]>;
  greenhouseHiringManagerRoleId?: Maybe<Scalars["UUID"]>;
  greenhouseRecruiterRoleId?: Maybe<Scalars["UUID"]>;
  guideCompetencyScoringEnabledByDefault: Scalars["Boolean"];
  guideOverallScoringEnabledByDefault: Scalars["Boolean"];
  hideOtherInterviewerNotesInExtension: Scalars["Boolean"];
  icimsAccessToken?: Maybe<Scalars["JSONString"]>;
  icimsAccessTokenUrl?: Maybe<Scalars["String"]>;
  icimsClientId?: Maybe<Scalars["JSONString"]>;
  icimsClientSecret?: Maybe<Scalars["JSONString"]>;
  icimsCustomerId?: Maybe<Scalars["String"]>;
  icimsEnabled: Scalars["Boolean"];
  icimsTokenExpiresAt?: Maybe<Scalars["DateTime"]>;
  icimsTokenType?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  incomingCallRecruiterNotFoundMessage?: Maybe<Scalars["String"]>;
  individualVoipNumbersEnabled: Scalars["Boolean"];
  interviewEnabled: Scalars["Boolean"];
  inviteScheduledInterviewers: Scalars["Boolean"];
  inviteScheduledInterviewersAsShadowUsers: Scalars["Boolean"];
  isBullhornAuthorized: Scalars["Boolean"];
  isZoomDeauthorizeRunning: Scalars["Boolean"];
  leverAccessToken?: Maybe<Scalars["JSONString"]>;
  leverCreateSecretNotes: Scalars["Boolean"];
  leverHiringManagerRoleId?: Maybe<Scalars["UUID"]>;
  leverOwnerRoleId?: Maybe<Scalars["UUID"]>;
  leverRefreshToken?: Maybe<Scalars["JSONString"]>;
  leverSignatureToken?: Maybe<Scalars["JSONString"]>;
  leverStateToken?: Maybe<Scalars["JSONString"]>;
  leverTokenExpiresAt?: Maybe<Scalars["DateTime"]>;
  leverUseSandbox: Scalars["Boolean"];
  maskPersonalPhoneNumber: Scalars["Boolean"];
  maximumCallDurationForNotetakerToRejoin?: Maybe<Scalars["Int"]>;
  metadataUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  oktaDomain?: Maybe<Scalars["String"]>;
  parseCandidateInfoFromEventBody: Scalars["Boolean"];
  pasteAiNotesInScorecard: Scalars["Boolean"];
  planEnabled: Scalars["Boolean"];
  planUserRoles: Array<PlanUserRole>;
  playCallDisclaimer: Scalars["Boolean"];
  playInPersonRecordingDisclaimer: Scalars["Boolean"];
  postNotesToAts: Scalars["Boolean"];
  preventOutsideUsAccess: Scalars["Boolean"];
  preventRedactionForTraining: Scalars["Boolean"];
  primaryAreaCode?: Maybe<Scalars["String"]>;
  ratingDescriptions: Scalars["JSONString"];
  recallSilenceDetectionTimeout?: Maybe<Scalars["Int"]>;
  recallZoomOauthCredentialId?: Maybe<Scalars["UUID"]>;
  recallZoomOauthCredentialInstallFinishedAt?: Maybe<Scalars["DateTime"]>;
  recallZoomOauthCredentialInstallStartedAt?: Maybe<Scalars["DateTime"]>;
  recallZoomOauthCredentialInstallStatus?: Maybe<InstallZoomNotetakerAppJobProcessingStatus>;
  redactEeocFamilyEnabled: Scalars["Boolean"];
  redactEeocHighRiskQuestionsEnabled: Scalars["Boolean"];
  redactEeocRaceEnabled: Scalars["Boolean"];
  redactEeocSexEnabled: Scalars["Boolean"];
  redactedAt?: Maybe<Scalars["DateTime"]>;
  replaceGenderedPronouns: Scalars["Boolean"];
  requireActiveUserToImport: Scalars["Boolean"];
  requireSamlRoleMappingForSelfSignUp: Scalars["Boolean"];
  requireWhitelistUserToImport: Scalars["Boolean"];
  retentionDaysSinceCandidateHired: Scalars["Float"];
  retentionDaysSinceCandidateRejected: Scalars["Float"];
  retentionDaysSinceInterview: Scalars["Float"];
  retentionDaysSinceLastInterview: Scalars["Float"];
  retentionEmailSendTime: Scalars["Time"];
  retentionEmailSendTimezone: Scalars["String"];
  retentionNotificationDays?: Maybe<Array<Maybe<Scalars["String"]>>>;
  samlRoleMapping: Scalars["JSONString"];
  scoringEnabled: Scalars["Boolean"];
  secondaryAreaCodes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  sendCancelRecordingChatMessage: Scalars["Boolean"];
  sendInterviewSignUpNudges: Scalars["Boolean"];
  sendInvitationsForSsoCreatedUsers: Scalars["Boolean"];
  sendSignUpNudges: Scalars["Boolean"];
  senseApiKey?: Maybe<Scalars["JSONString"]>;
  senseEnabled: Scalars["Boolean"];
  sessionDuration: Scalars["Float"];
  showStarRatings: Scalars["Boolean"];
  slackAppInstalled: Scalars["Boolean"];
  slackId?: Maybe<Scalars["String"]>;
  smartrecruitersAccessToken?: Maybe<Scalars["JSONString"]>;
  smartrecruitersApiKey?: Maybe<Scalars["JSONString"]>;
  smartrecruitersCoordinatorRoleId?: Maybe<Scalars["UUID"]>;
  smartrecruitersEnabled: Scalars["Boolean"];
  smartrecruitersExecutiveRoleId?: Maybe<Scalars["UUID"]>;
  smartrecruitersHiringManagerRoleId?: Maybe<Scalars["UUID"]>;
  smartrecruitersRecruiterRoleId?: Maybe<Scalars["UUID"]>;
  smartrecruitersRefreshToken?: Maybe<Scalars["JSONString"]>;
  smartrecruitersStateToken?: Maybe<Scalars["JSONString"]>;
  smartrecruitersTokenExpiresAt?: Maybe<Scalars["DateTime"]>;
  syncCallGuidesFromAts: Scalars["Boolean"];
  syncCandidateOffersFromAts: Scalars["Boolean"];
  teamsNotetakerDisclaimer?: Maybe<Scalars["String"]>;
  teamsNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
  teamsNotetakerDisplayImageFilename?: Maybe<Scalars["String"]>;
  teamsNotetakerDisplayName?: Maybe<Scalars["String"]>;
  teamsNotetakerEnabled: Scalars["Boolean"];
  textBeforeCallEnabled: Scalars["Boolean"];
  twilioBusinessProfileSid?: Maybe<Scalars["String"]>;
  twilioTrustProductCnamSid?: Maybe<Scalars["String"]>;
  twilioTrustProductShakenStirSid?: Maybe<Scalars["String"]>;
  twilioTrustProductVoiceIntegritySid?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  virtualOnsiteEnabledGoogleMeet: Scalars["Boolean"];
  virtualOnsiteEnabledMsTeams: Scalars["Boolean"];
  virtualOnsiteEnabledZoom: Scalars["Boolean"];
  zoomAccountId?: Maybe<Scalars["String"]>;
  zoomNotetakerDisclaimer?: Maybe<Scalars["String"]>;
  zoomNotetakerDisclaimerType: NotetakerRecorderDisclaimerType;
  zoomNotetakerDisplayImageFilename?: Maybe<Scalars["String"]>;
  zoomNotetakerDisplayName?: Maybe<Scalars["String"]>;
  zoomNotetakerEnabled: Scalars["Boolean"];
};

export type OrganizationFeatureAccess = {
  __typename?: "OrganizationFeatureAccess";
  id: Scalars["ID"];
  isVisible: Scalars["Boolean"];
  marketingRedirect: Scalars["Boolean"];
  name: FeatureName;
  settingLevel: FeatureSettingLevel;
  showCta: Scalars["Boolean"];
};

export type PaginatedJobDescriptionVisibleTo = {
  __typename?: "PaginatedJobDescriptionVisibleTo";
  resultCount: Scalars["Int"];
  results: Array<JobDescriptionVisibleTo>;
};

export type PaginatedJobDescriptions = {
  __typename?: "PaginatedJobDescriptions";
  pageInfo: OffsetPaginationInfo;
  results: Array<JobDescriptionListItem>;
};

export type PartnerAccess = {
  __typename?: "PartnerAccess";
  hasAccess: Scalars["Boolean"];
};

export type Plan = {
  __typename?: "Plan";
  createdAt: Scalars["DateTime"];
  creator: User;
  creatorId: Scalars["UUID"];
  id: Scalars["UUID"];
  jobDescription: JobDescription;
  jobDescriptionId: Scalars["UUID"];
  organizationId: Scalars["UUID"];
  planInterviews: Array<PlanInterview>;
  planProcessingCompletedAt?: Maybe<Scalars["DateTime"]>;
  planProcessingStartedAt?: Maybe<Scalars["DateTime"]>;
  planProcessingStatus?: Maybe<PlanProcessingStatus>;
  updatedAt: Scalars["DateTime"];
};

export type PlanInterview = {
  __typename?: "PlanInterview";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  name?: Maybe<Scalars["String"]>;
  order: Scalars["Int"];
  organizationId: Scalars["UUID"];
  planId: Scalars["UUID"];
  questionGroups: Array<PlanQuestionGroup>;
  updatedAt: Scalars["DateTime"];
};

export enum PlanJobDescriptionTone {
  Friendly = "FRIENDLY",
  Fun = "FUN",
  Inspirational = "INSPIRATIONAL",
  Professional = "PROFESSIONAL",
  Visionary = "VISIONARY",
}

export enum PlanProcessingStatus {
  Dispatched = "DISPATCHED",
  Failed = "FAILED",
  PlanCompetenciesInProgress = "PLAN_COMPETENCIES_IN_PROGRESS",
  PlanCompleted = "PLAN_COMPLETED",
  PlanInProgress = "PLAN_IN_PROGRESS",
  QuestionsCompleted = "QUESTIONS_COMPLETED",
  QuestionsInProgress = "QUESTIONS_IN_PROGRESS",
}

export type PlanQuestion = {
  __typename?: "PlanQuestion";
  groupId: Scalars["UUID"];
  id: Scalars["UUID"];
  order?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  type: PlanQuestionType;
};

export type PlanQuestionGroup = {
  __typename?: "PlanQuestionGroup";
  competency?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  jobDescriptionId: Scalars["UUID"];
  order?: Maybe<Scalars["Int"]>;
  planInterviewId?: Maybe<Scalars["UUID"]>;
  questions: Array<PlanQuestion>;
  referenceLocation?: Maybe<Scalars["String"]>;
  referenceText?: Maybe<Scalars["String"]>;
  source?: Maybe<QuestionGroupSource>;
};

export enum PlanQuestionType {
  Behavioral = "BEHAVIORAL",
  CaseStudy = "CASE_STUDY",
  Custom = "CUSTOM",
  Other = "OTHER",
  Performance = "PERFORMANCE",
  Screening = "SCREENING",
  Situational = "SITUATIONAL",
  Technical = "TECHNICAL",
}

export enum PlanQuestionsProcessingStatus {
  Completed = "COMPLETED",
  Dispatched = "DISPATCHED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
}

export type PlanQuestionsStatus = {
  __typename?: "PlanQuestionsStatus";
  jobDescriptionId: Scalars["String"];
  questionsProcessingStatus?: Maybe<PlanQuestionsProcessingStatus>;
};

export type PlanStatus = {
  __typename?: "PlanStatus";
  planId: Scalars["String"];
  planProcessingStatus?: Maybe<PlanProcessingStatus>;
};

export type PlanUserRole = {
  __typename?: "PlanUserRole";
  canManageOrganizationSettings?: Maybe<Scalars["Boolean"]>;
  canManageUserRoles?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  formattedName?: Maybe<Scalars["String"]>;
  formattedPermissions?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: PlanUserRoleName;
  organizationId: Scalars["UUID"];
  permissions: Array<PlanUserRolePermission>;
  updatedAt: Scalars["DateTime"];
};

export enum PlanUserRoleName {
  Admin = "ADMIN",
  Editor = "EDITOR",
}

export enum PlanUserRolePermission {
  CreateJobDescription = "CREATE_JOB_DESCRIPTION",
  ManageOrganizationSettings = "MANAGE_ORGANIZATION_SETTINGS",
  ManageUserRoles = "MANAGE_USER_ROLES",
  ViewAllJobDescriptions = "VIEW_ALL_JOB_DESCRIPTIONS",
}

export type Position = {
  __typename?: "Position";
  autoJoinInterview: Scalars["Boolean"];
  bullhornId?: Maybe<Scalars["Int"]>;
  candidateSummaryTemplateId?: Maybe<Scalars["UUID"]>;
  candidatesCount?: Maybe<Scalars["Int"]>;
  clientId: Scalars["UUID"];
  createdAt: Scalars["DateTime"];
  customAtsId?: Maybe<Scalars["String"]>;
  customAtsStatus?: Maybe<Scalars["String"]>;
  customAtsUrl?: Maybe<Scalars["String"]>;
  displayTitle: Scalars["String"];
  greenhouseId?: Maybe<Scalars["Float"]>;
  greenhouseStatus?: Maybe<Scalars["String"]>;
  icimsId?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  inforJobCode?: Maybe<Scalars["String"]>;
  leverPostingId?: Maybe<Scalars["String"]>;
  leverState?: Maybe<Scalars["String"]>;
  locations?: Maybe<Array<Maybe<Scalars["String"]>>>;
  memberships: Array<Membership>;
  membershipsCount?: Maybe<Scalars["Int"]>;
  mergeId?: Maybe<Scalars["String"]>;
  mergeRemoteId?: Maybe<Scalars["String"]>;
  mergeStatus?: Maybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  requisitionId?: Maybe<Scalars["String"]>;
  smartrecruitersId?: Maybe<Scalars["String"]>;
  smartrecruitersStatus?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  titleInsensitive?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  videos: Scalars["JSONString"];
};

export type PositionRole = {
  __typename?: "PositionRole";
  canAccessTeamPrivateCalls?: Maybe<Scalars["Boolean"]>;
  canAccessTeamPublicCalls?: Maybe<Scalars["Boolean"]>;
  canAccessTeamRestrictedCalls?: Maybe<Scalars["Boolean"]>;
  canCreateTrainings?: Maybe<Scalars["Boolean"]>;
  canManageHiringTeam?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  formattedName?: Maybe<Scalars["String"]>;
  formattedPermissions?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: PositionRoleName;
  organizationId: Scalars["UUID"];
  permissions: Array<PositionRolePermission>;
  updatedAt: Scalars["DateTime"];
};

export enum PositionRoleName {
  HiringTeamAdmin = "HIRING_TEAM_ADMIN",
  Interviewer = "INTERVIEWER",
  Member = "MEMBER",
}

export enum PositionRolePermission {
  AccessOthersNotes = "ACCESS_OTHERS_NOTES",
  AccessTeamPrivateCalls = "ACCESS_TEAM_PRIVATE_CALLS",
  AccessTeamPublicCalls = "ACCESS_TEAM_PUBLIC_CALLS",
  AccessTeamRestrictedCalls = "ACCESS_TEAM_RESTRICTED_CALLS",
  CreateTrainings = "CREATE_TRAININGS",
  ManageAllScores = "MANAGE_ALL_SCORES",
  ManageHiringTeam = "MANAGE_HIRING_TEAM",
  ShareRecordingsExternally = "SHARE_RECORDINGS_EXTERNALLY",
  SubscribesToTeamAlerts = "SUBSCRIBES_TO_TEAM_ALERTS",
}

export type Query = {
  __typename?: "Query";
  /**
   * Un-paginated list of all job descriptions the current user can view
   * @deprecated Prefer `paginated_all_job_descriptions`
   */
  allJobDescriptions: Array<JobDescriptionListItem>;
  currentUser?: Maybe<User>;
  customTemplate: JobDescriptionTemplate;
  isAuthenticated: Scalars["Boolean"];
  jobDescription: JobDescription;
  jobDescriptionAnalysis?: Maybe<JobDescriptionAnalysis>;
  jobDescriptionCreatorSession: JobDescriptionCreatorSession;
  /**
   * Un-paginated list of job descriptions created by the current user
   * @deprecated Prefer `paginated_job_descriptions`
   */
  jobDescriptions: Array<JobDescriptionListItem>;
  /** Paginated list of all job descriptions the current user can view */
  paginatedAllJobDescriptions: PaginatedJobDescriptions;
  /** Paginated list of job descriptions created by the current user */
  paginatedJobDescriptions: PaginatedJobDescriptions;
  /** Paginated list of all job descriptions shared with the current user */
  paginatedSharedJobDescriptions: PaginatedJobDescriptions;
  partnerAccess?: Maybe<PartnerAccess>;
  plan: Plan;
  planQuestionsStatus: PlanQuestionsStatus;
  planStatus: PlanStatus;
  /**
   * Un-paginated list of all job descriptions shared with the current user
   * @deprecated Prefer `paginated_shared_job_descriptions`
   */
  sharedJobDescriptions: Array<JobDescriptionListItem>;
};

export type QueryAllJobDescriptionsArgs = {
  hasPlan?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryJobDescriptionArgs = {
  id: Scalars["ID"];
};

export type QueryJobDescriptionAnalysisArgs = {
  blocks: Array<TextBlock>;
  editorContent: Scalars["JSONString"];
  id: Scalars["ID"];
  text: Scalars["String"];
  whitelistedWords?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryJobDescriptionCreatorSessionArgs = {
  jobDescriptionId: Scalars["ID"];
};

export type QueryJobDescriptionsArgs = {
  hasPlan?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryPaginatedAllJobDescriptionsArgs = {
  hasPlan?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<SearchTermPaginationDetails>;
};

export type QueryPaginatedJobDescriptionsArgs = {
  hasPlan?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<SearchTermPaginationDetails>;
};

export type QueryPaginatedSharedJobDescriptionsArgs = {
  hasPlan?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<SearchTermPaginationDetails>;
};

export type QueryPartnerAccessArgs = {
  email: Scalars["String"];
};

export type QueryPlanArgs = {
  id: Scalars["ID"];
};

export type QueryPlanQuestionsStatusArgs = {
  jobDescriptionId: Scalars["ID"];
};

export type QueryPlanStatusArgs = {
  planId: Scalars["ID"];
};

export type QuerySharedJobDescriptionsArgs = {
  hasPlan?: InputMaybe<Scalars["Boolean"]>;
};

export enum QuestionGroupSource {
  JobDescription = "JOB_DESCRIPTION",
  Static = "STATIC",
  UserFocusArea = "USER_FOCUS_AREA",
  UserManuallyAdded = "USER_MANUALLY_ADDED",
}

export type RemoveJobDescriptionShare = {
  __typename?: "RemoveJobDescriptionShare";
  jobDescription: JobDescription;
};

export type ReorderPlanInterviews = {
  __typename?: "ReorderPlanInterviews";
  plan?: Maybe<Plan>;
};

export type ReorderPlanQuestionGroups = {
  __typename?: "ReorderPlanQuestionGroups";
  planInterview?: Maybe<PlanInterview>;
};

export type ReorderPlanQuestions = {
  __typename?: "ReorderPlanQuestions";
  questionGroup?: Maybe<PlanQuestionGroup>;
};

/** User is requesting to upgrade their organization to plan */
export type RequestPlanUpgrade = {
  __typename?: "RequestPlanUpgrade";
  success?: Maybe<Scalars["Boolean"]>;
};

export type SearchTermPaginationDetails = {
  limit?: InputMaybe<Scalars["Int"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
};

export type ShareJobDescription = {
  __typename?: "ShareJobDescription";
  jobDescription: JobDescription;
};

export type SignOut = {
  __typename?: "SignOut";
  isAuthenticated: Scalars["Boolean"];
};

export type TextBlock = {
  text: Scalars["String"];
  type: Scalars["String"];
};

export type Unimpersonate = {
  __typename?: "Unimpersonate";
  currentUser: User;
  redirectTo: Scalars["String"];
};

export type UpdateCurrentUserBrowserExtensionVersion = {
  __typename?: "UpdateCurrentUserBrowserExtensionVersion";
  currentUser: User;
};

export type UpdateJobDescription = {
  __typename?: "UpdateJobDescription";
  jobDescription: JobDescription;
};

export type UpdateJobDescriptionCreatorSession = {
  __typename?: "UpdateJobDescriptionCreatorSession";
  jobDescriptionCreatorSession: JobDescriptionCreatorSession;
};

export type UpdatePlanInterview = {
  __typename?: "UpdatePlanInterview";
  planInterview?: Maybe<PlanInterview>;
};

export type UpdatePlanQuestion = {
  __typename?: "UpdatePlanQuestion";
  planQuestion?: Maybe<PlanQuestion>;
};

export type UpdatePlanQuestionGroup = {
  __typename?: "UpdatePlanQuestionGroup";
  planQuestionGroup?: Maybe<PlanQuestionGroup>;
};

export type User = {
  __typename?: "User";
  browserExtensionVersion?: Maybe<Scalars["String"]>;
  bullhornId?: Maybe<Scalars["Int"]>;
  callNoteSubscription: UserSubscriptionSettings;
  callShareSubscription: UserSubscriptionSettings;
  createdAt: Scalars["DateTime"];
  customAtsId?: Maybe<Scalars["String"]>;
  defaultCallType: CallType;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  deletedById?: Maybe<Scalars["UUID"]>;
  email: Scalars["String"];
  emailAliases: Array<Maybe<Scalars["String"]>>;
  enabledFeatures: Array<Scalars["String"]>;
  excludeFromAutoJoin: Scalars["Boolean"];
  firstName?: Maybe<Scalars["String"]>;
  freemiumSignUp: Scalars["Boolean"];
  fullName: Scalars["String"];
  googleScopes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  googleUserId?: Maybe<Scalars["String"]>;
  greenhouseDisabled?: Maybe<Scalars["Boolean"]>;
  greenhouseDisabledIds: Array<Maybe<Scalars["Float"]>>;
  greenhouseId?: Maybe<Scalars["Float"]>;
  hasViewedTranscriptionWarning: Scalars["Boolean"];
  icimsId?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  impersonatedFeatures: Array<Scalars["String"]>;
  intercomUserHash: Scalars["String"];
  internalUserRole?: Maybe<InternalUserRoleName>;
  interviewImportRule?: Maybe<UserInterviewImportRule>;
  interviewPrepSubscription: UserSubscriptionSettings;
  interviewRecapSubscription: UserSubscriptionSettings;
  invitedAt?: Maybe<Scalars["DateTime"]>;
  invitedById?: Maybe<Scalars["UUID"]>;
  isImpersonated: Scalars["Boolean"];
  isWhitelistedForAutoJoin: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  leverId?: Maybe<Scalars["String"]>;
  loginPermitted: Scalars["Boolean"];
  maskPersonalPhoneNumber: Scalars["Boolean"];
  memberships: Array<Membership>;
  monthlyAnalyticsSubscription: UserSubscriptionSettings;
  morningDigestSubscription: UserSubscriptionSettings;
  msftScopes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  msftUserId?: Maybe<Scalars["String"]>;
  oktaUserId?: Maybe<Scalars["String"]>;
  organization: Organization;
  organizationId: Scalars["UUID"];
  persona?: Maybe<UserPersona>;
  phoneNumber?: Maybe<Scalars["String"]>;
  phoneNumberExtension?: Maybe<Scalars["String"]>;
  phoneNumberVerified: Scalars["Boolean"];
  planUserRole?: Maybe<PlanUserRole>;
  planUserRoleId?: Maybe<Scalars["UUID"]>;
  primaryAreaCode?: Maybe<Scalars["String"]>;
  profilePicUrl?: Maybe<Scalars["String"]>;
  senseRequestId?: Maybe<Scalars["String"]>;
  showCandidateActionItemAlerts: Scalars["Boolean"];
  signUpCompletedAt?: Maybe<Scalars["DateTime"]>;
  signUpMethod: UserSignUpMethod;
  signUpMethodLabel: Scalars["String"];
  signUpStartedAt?: Maybe<Scalars["DateTime"]>;
  signUpState: Scalars["String"];
  slackId?: Maybe<Scalars["String"]>;
  smartrecruitersId?: Maybe<Scalars["String"]>;
  timezoneName: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  userRole?: Maybe<UserRole>;
  userRoleGrantedById?: Maybe<Scalars["UUID"]>;
  userRoleId?: Maybe<Scalars["UUID"]>;
  weeklyRecapSubscription: UserSubscriptionSettings;
  zoomAppInstalledAt?: Maybe<Scalars["DateTime"]>;
  zoomDisplayName?: Maybe<Scalars["String"]>;
  zoomEmail?: Maybe<Scalars["String"]>;
  zoomFirstName?: Maybe<Scalars["String"]>;
  zoomId?: Maybe<Scalars["String"]>;
  zoomLastName?: Maybe<Scalars["String"]>;
  zoomSettings?: Maybe<Scalars["JSONString"]>;
  zoomUserType?: Maybe<Scalars["Int"]>;
};

export enum UserInterviewImportRule {
  AllMyInterviews = "ALL_MY_INTERVIEWS",
  None = "NONE",
  OnlyInterviewerMyInterviews = "ONLY_INTERVIEWER_MY_INTERVIEWS",
}

export enum UserPersona {
  CoordinatorOps = "COORDINATOR_OPS",
  HiringManager = "HIRING_MANAGER",
  Interviewer = "INTERVIEWER",
  Recruiter = "RECRUITER",
  TaLeader = "TA_LEADER",
}

export type UserRole = {
  __typename?: "UserRole";
  canAccessPrivateCalls?: Maybe<Scalars["Boolean"]>;
  canAccessPublicCalls?: Maybe<Scalars["Boolean"]>;
  canAccessRestrictedCalls?: Maybe<Scalars["Boolean"]>;
  canCreatePosition?: Maybe<Scalars["Boolean"]>;
  canCreateTrainings?: Maybe<Scalars["Boolean"]>;
  canDeleteCall?: Maybe<Scalars["Boolean"]>;
  canDeleteCandidates?: Maybe<Scalars["Boolean"]>;
  canManageAllTrainings?: Maybe<Scalars["Boolean"]>;
  canManageHiringTeam?: Maybe<Scalars["Boolean"]>;
  canManageIntegrationSettings?: Maybe<Scalars["Boolean"]>;
  canManageOrganizationSettings?: Maybe<Scalars["Boolean"]>;
  canManageUserRoles?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
  formattedName?: Maybe<Scalars["String"]>;
  formattedPermissions?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: UserRoleName;
  organizationId: Scalars["UUID"];
  permissions: Array<UserRolePermission>;
  updatedAt: Scalars["DateTime"];
};

export enum UserRoleName {
  Basic = "BASIC",
  Recruiter = "RECRUITER",
  SiteAdmin = "SITE_ADMIN",
}

export enum UserRolePermission {
  AccessAllPrivateCalls = "ACCESS_ALL_PRIVATE_CALLS",
  AccessAllPublicCalls = "ACCESS_ALL_PUBLIC_CALLS",
  AccessAllRestrictedCalls = "ACCESS_ALL_RESTRICTED_CALLS",
  AccessOthersNotes = "ACCESS_OTHERS_NOTES",
  CreatePosition = "CREATE_POSITION",
  CreateTrainings = "CREATE_TRAININGS",
  DeleteCall = "DELETE_CALL",
  DeleteCandidate = "DELETE_CANDIDATE",
  ManageAllPlaylists = "MANAGE_ALL_PLAYLISTS",
  ManageAllRedactions = "MANAGE_ALL_REDACTIONS",
  ManageAllScores = "MANAGE_ALL_SCORES",
  ManageAllTrainings = "MANAGE_ALL_TRAININGS",
  ManageHiringTeam = "MANAGE_HIRING_TEAM",
  ManageIntegrationSettings = "MANAGE_INTEGRATION_SETTINGS",
  ManageInterviewerQualityReport = "MANAGE_INTERVIEWER_QUALITY_REPORT",
  ManageOrganizationSettings = "MANAGE_ORGANIZATION_SETTINGS",
  ManageUserRoles = "MANAGE_USER_ROLES",
  ShareRecordingsExternally = "SHARE_RECORDINGS_EXTERNALLY",
  SubscribesToAlerts = "SUBSCRIBES_TO_ALERTS",
  ViewInterviewerQualityReport = "VIEW_INTERVIEWER_QUALITY_REPORT",
}

export enum UserSignUpMethod {
  Api = "API",
  Invitation = "INVITATION",
  Okta = "OKTA",
  Onboarding = "ONBOARDING",
  ScheduledInterview = "SCHEDULED_INTERVIEW",
  SelfSignUp = "SELF_SIGN_UP",
  Zoom = "ZOOM",
}

export enum UserSubscriptionSettings {
  All = "ALL",
  Email = "EMAIL",
  None = "NONE",
  Slack = "SLACK",
}

export type CreatorTemplateSectionFragment = {
  __typename?: "JobDescriptionTemplateCustomSection";
  header: string;
  text: string;
  order: number;
};

export type CurrentPlanUserFragment = {
  __typename?: "User";
  id: any;
  firstName?: string | null;
  lastName?: string | null;
  fullName: string;
  email: string;
  profilePicUrl?: string | null;
  isImpersonated: boolean;
  createdAt: any;
  signUpCompletedAt?: any | null;
  signUpState: string;
  enabledFeatures: Array<string>;
  impersonatedFeatures: Array<string>;
  browserExtensionVersion?: string | null;
  intercomUserHash: string;
  signUpMethod: UserSignUpMethod;
  persona?: UserPersona | null;
  organization: {
    __typename?: "Organization";
    id: any;
    name: string;
    freemium: boolean;
    planEnabled: boolean;
    interviewEnabled: boolean;
    defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
    allowPlanJobDescriptionToneOverride: boolean;
    featureAccess?: Array<{
      __typename?: "OrganizationFeatureAccess";
      id: string;
      name: FeatureName;
      settingLevel: FeatureSettingLevel;
      isVisible: boolean;
      showCta: boolean;
      marketingRedirect: boolean;
    }> | null;
  };
  planUserRole?: {
    __typename?: "PlanUserRole";
    id: any;
    name: PlanUserRoleName;
    formattedName?: string | null;
  } | null;
  userRole?: {
    __typename?: "UserRole";
    id: any;
    name: UserRoleName;
    formattedName?: string | null;
    permissions: Array<UserRolePermission>;
    canManageOrganizationSettings?: boolean | null;
    canManageIntegrationSettings?: boolean | null;
    canManageHiringTeam?: boolean | null;
    canManageUserRoles?: boolean | null;
    canCreatePosition?: boolean | null;
    canDeleteCall?: boolean | null;
    canCreateTrainings?: boolean | null;
    canManageAllTrainings?: boolean | null;
    canDeleteCandidates?: boolean | null;
  } | null;
  memberships: Array<{
    __typename?: "Membership";
    position: {
      __typename?: "Position";
      id: any;
      title: string;
      displayTitle: string;
    };
    role: {
      __typename?: "PositionRole";
      id: any;
      permissions: Array<PositionRolePermission>;
      canManageHiringTeam?: boolean | null;
      canCreateTrainings?: boolean | null;
    };
  }>;
};

export type PlanFragment = {
  __typename?: "Plan";
  id: any;
  planProcessingStatus?: PlanProcessingStatus | null;
  createdAt: any;
  jobDescription: {
    __typename?: "JobDescription";
    id: any;
    title?: string | null;
    editorContent?: any | null;
    lastModifiedAt?: any | null;
    jobDescriptionState?: JobDescriptionState | null;
  };
  planInterviews: Array<{
    __typename?: "PlanInterview";
    id: any;
    name?: string | null;
    questionGroups: Array<{
      __typename?: "PlanQuestionGroup";
      id: any;
      planInterviewId?: any | null;
      competency?: string | null;
      referenceText?: string | null;
      source?: QuestionGroupSource | null;
      questions: Array<{
        __typename?: "PlanQuestion";
        id: any;
        groupId: any;
        question?: string | null;
        type: PlanQuestionType;
      }>;
    }>;
  }>;
};

export type PlanInterviewFragment = {
  __typename?: "PlanInterview";
  id: any;
  name?: string | null;
  questionGroups: Array<{
    __typename?: "PlanQuestionGroup";
    id: any;
    planInterviewId?: any | null;
    competency?: string | null;
    referenceText?: string | null;
    source?: QuestionGroupSource | null;
    questions: Array<{
      __typename?: "PlanQuestion";
      id: any;
      groupId: any;
      question?: string | null;
      type: PlanQuestionType;
    }>;
  }>;
};

export type PlanOrganizationFeatureAccessFragment = {
  __typename?: "OrganizationFeatureAccess";
  id: string;
  name: FeatureName;
  settingLevel: FeatureSettingLevel;
  isVisible: boolean;
  showCta: boolean;
  marketingRedirect: boolean;
};

export type PlanOrganizationFragment = {
  __typename?: "Organization";
  id: any;
  name: string;
  freemium: boolean;
  planEnabled: boolean;
  interviewEnabled: boolean;
  defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
  allowPlanJobDescriptionToneOverride: boolean;
  featureAccess?: Array<{
    __typename?: "OrganizationFeatureAccess";
    id: string;
    name: FeatureName;
    settingLevel: FeatureSettingLevel;
    isVisible: boolean;
    showCta: boolean;
    marketingRedirect: boolean;
  }> | null;
};

export type PlanQuestionFragment = {
  __typename?: "PlanQuestion";
  id: any;
  groupId: any;
  question?: string | null;
  type: PlanQuestionType;
};

export type PlanQuestionGroupFragment = {
  __typename?: "PlanQuestionGroup";
  id: any;
  planInterviewId?: any | null;
  competency?: string | null;
  referenceText?: string | null;
  source?: QuestionGroupSource | null;
  questions: Array<{
    __typename?: "PlanQuestion";
    id: any;
    groupId: any;
    question?: string | null;
    type: PlanQuestionType;
  }>;
};

export type JobDescriptionQuestionGroupFragment = {
  __typename?: "PlanQuestionGroup";
  id: any;
  competency?: string | null;
  referenceText?: string | null;
  jobDescriptionId: any;
  source?: QuestionGroupSource | null;
  questions: Array<{
    __typename?: "PlanQuestion";
    id: any;
    question?: string | null;
    type: PlanQuestionType;
  }>;
};

export type ShareUserFragment = {
  __typename?: "User";
  id: any;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName: string;
  profilePicUrl?: string | null;
};

export type AddPlanInterviewMutationVariables = Exact<{
  planId: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
}>;

export type AddPlanInterviewMutation = {
  __typename?: "Mutation";
  addPlanInterview?: {
    __typename?: "AddPlanInterview";
    planInterview?: {
      __typename?: "PlanInterview";
      id: any;
      name?: string | null;
      questionGroups: Array<{
        __typename?: "PlanQuestionGroup";
        id: any;
        planInterviewId?: any | null;
        competency?: string | null;
        referenceText?: string | null;
        source?: QuestionGroupSource | null;
        questions: Array<{
          __typename?: "PlanQuestion";
          id: any;
          groupId: any;
          question?: string | null;
          type: PlanQuestionType;
        }>;
      }>;
    } | null;
  } | null;
};

export type AddPlanQuestionMutationVariables = Exact<{
  planId: Scalars["ID"];
  groupId: Scalars["ID"];
  text: Scalars["String"];
  order: Scalars["Int"];
}>;

export type AddPlanQuestionMutation = {
  __typename?: "Mutation";
  addPlanQuestion?: {
    __typename?: "AddPlanQuestion";
    question?: {
      __typename?: "PlanQuestion";
      id: any;
      groupId: any;
      question?: string | null;
      type: PlanQuestionType;
    } | null;
  } | null;
};

export type AddPlanQuestionGroupMutationVariables = Exact<{
  planId: Scalars["ID"];
  interviewId: Scalars["ID"];
  competency: Scalars["String"];
  order: Scalars["Int"];
}>;

export type AddPlanQuestionGroupMutation = {
  __typename?: "Mutation";
  addPlanQuestionGroup?: {
    __typename?: "AddPlanQuestionGroup";
    questionGroup?: {
      __typename?: "PlanQuestionGroup";
      id: any;
      planInterviewId?: any | null;
      competency?: string | null;
      referenceText?: string | null;
      source?: QuestionGroupSource | null;
      questions: Array<{
        __typename?: "PlanQuestion";
        id: any;
        groupId: any;
        question?: string | null;
        type: PlanQuestionType;
      }>;
    } | null;
  } | null;
};

export type CreateJobDescriptionMutationVariables = Exact<{
  title: Scalars["String"];
  jobDescriptionState: JobDescriptionState;
}>;

export type CreateJobDescriptionMutation = {
  __typename?: "Mutation";
  createJobDescription?: {
    __typename?: "CreateJobDescription";
    jobDescription: {
      __typename?: "JobDescription";
      id: any;
      title?: string | null;
    };
  } | null;
};

export type CreatePlanMutationVariables = Exact<{
  numberOfInterviews: Scalars["Int"];
  jobDescriptionId: Scalars["ID"];
  jobDescriptionText: Scalars["String"];
  includeRecruiterScreen: Scalars["Boolean"];
  additionalAreas?: InputMaybe<Scalars["String"]>;
}>;

export type CreatePlanMutation = {
  __typename?: "Mutation";
  createPlan?: { __typename?: "CreatePlan"; planId: string } | null;
};

export type DeleteJobDescriptionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteJobDescriptionMutation = {
  __typename?: "Mutation";
  deleteJobDescription?: {
    __typename?: "DeleteJobDescription";
    success: boolean;
  } | null;
};

export type DeletePlanInterviewMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeletePlanInterviewMutation = {
  __typename?: "Mutation";
  deletePlanInterview?: {
    __typename?: "DeletePlanInterview";
    success: boolean;
  } | null;
};

export type DeletePlanQuestionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeletePlanQuestionMutation = {
  __typename?: "Mutation";
  deletePlanQuestion?: {
    __typename?: "DeletePlanQuestion";
    success: boolean;
  } | null;
};

export type DeletePlanQuestionGroupMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeletePlanQuestionGroupMutation = {
  __typename?: "Mutation";
  deletePlanQuestionGroup?: {
    __typename?: "DeletePlanQuestionGroup";
    success: boolean;
  } | null;
};

export type GenerateAboutRoleMutationVariables = Exact<{
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  uniqueAspects: Scalars["String"];
  pitch: Scalars["String"];
  customTone?: InputMaybe<PlanJobDescriptionTone>;
}>;

export type GenerateAboutRoleMutation = {
  __typename?: "Mutation";
  generateAboutRole?: {
    __typename?: "GenerateAboutRole";
    aboutRole: string;
  } | null;
};

export type GeneratePlanQuestionsMutationVariables = Exact<{
  id: Scalars["ID"];
  text: Scalars["String"];
  blocks: Array<TextBlock> | TextBlock;
  regenerate?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GeneratePlanQuestionsMutation = {
  __typename?: "Mutation";
  generatePlanQuestions?: {
    __typename?: "GeneratePlanQuestions";
    jobDescription: {
      __typename?: "JobDescription";
      id: any;
      questionsProcessingStatus?: PlanQuestionsProcessingStatus | null;
    };
  } | null;
};

export type GenerateRequirementAreaTextMutationVariables = Exact<{
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  aboutRole: Scalars["String"];
  responsibilityArea: Scalars["String"];
  responsibilities: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GenerateRequirementAreaTextMutation = {
  __typename?: "Mutation";
  generateRequirementAreaText?: {
    __typename?: "GenerateRequirementAreaText";
    requirementAreaText: string;
  } | null;
};

export type GenerateRequirementAreasMutationVariables = Exact<{
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  aboutRole: Scalars["String"];
}>;

export type GenerateRequirementAreasMutation = {
  __typename?: "Mutation";
  generateRequirementAreas?: {
    __typename?: "GenerateRequirementAreas";
    requirementAreas: Array<string>;
  } | null;
};

export type GenerateRequirementsMutationVariables = Exact<{
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  aboutRole: Scalars["String"];
  responsibilities: Array<Scalars["String"]> | Scalars["String"];
  topic: JobRequirementTopic;
  partialRequirements: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GenerateRequirementsMutation = {
  __typename?: "Mutation";
  generateRequirements?: {
    __typename?: "GenerateRequirements";
    requirements: Array<string>;
  } | null;
};

export type GenerateResponsibilitiesMutationVariables = Exact<{
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  aboutRole: Scalars["String"];
  responsibilityArea: Scalars["String"];
  priorSummaries: Array<Scalars["String"]> | Scalars["String"];
  upcomingResponsibilityAreas: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GenerateResponsibilitiesMutation = {
  __typename?: "Mutation";
  generateResponsibilities?: {
    __typename?: "GenerateResponsibilities";
    responsibilities: Array<string>;
  } | null;
};

export type GenerateSimplifiedTextMutationVariables = Exact<{
  id: Scalars["ID"];
  text: Scalars["String"];
  jobDescriptionText: Scalars["String"];
}>;

export type GenerateSimplifiedTextMutation = {
  __typename?: "Mutation";
  generateSimplifiedText?: {
    __typename?: "GenerateSimplifiedText";
    simplifiedText: string;
    problem?: string | null;
    isComplex: boolean;
  } | null;
};

export type MoveQuestionGroupToInterviewMutationVariables = Exact<{
  planId: Scalars["ID"];
  questionGroupId: Scalars["ID"];
  sourceInterviewId: Scalars["ID"];
  destinationInterviewId: Scalars["ID"];
  destinationQuestionGroupIds:
    | Array<InputMaybe<Scalars["ID"]>>
    | InputMaybe<Scalars["ID"]>;
}>;

export type MoveQuestionGroupToInterviewMutation = {
  __typename?: "Mutation";
  moveQuestionGroupToInterview?: {
    __typename?: "MoveQuestionGroupToInterview";
    plan?: {
      __typename?: "Plan";
      id: any;
      planProcessingStatus?: PlanProcessingStatus | null;
      createdAt: any;
      jobDescription: {
        __typename?: "JobDescription";
        id: any;
        title?: string | null;
        editorContent?: any | null;
        lastModifiedAt?: any | null;
        jobDescriptionState?: JobDescriptionState | null;
      };
      planInterviews: Array<{
        __typename?: "PlanInterview";
        id: any;
        name?: string | null;
        questionGroups: Array<{
          __typename?: "PlanQuestionGroup";
          id: any;
          planInterviewId?: any | null;
          competency?: string | null;
          referenceText?: string | null;
          source?: QuestionGroupSource | null;
          questions: Array<{
            __typename?: "PlanQuestion";
            id: any;
            groupId: any;
            question?: string | null;
            type: PlanQuestionType;
          }>;
        }>;
      }>;
    } | null;
  } | null;
};

export type RemoveJobDescriptionShareMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveJobDescriptionShareMutation = {
  __typename?: "Mutation";
  removeJobDescriptionShare?: {
    __typename?: "RemoveJobDescriptionShare";
    jobDescription: {
      __typename?: "JobDescription";
      id: any;
      internalShares: Array<{
        __typename?: "JobDescriptionShare";
        id: any;
        sharedTo: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  } | null;
};

export type ReorderPlanInterviewsMutationVariables = Exact<{
  planId: Scalars["ID"];
  interviewIds: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
}>;

export type ReorderPlanInterviewsMutation = {
  __typename?: "Mutation";
  reorderPlanInterviews?: {
    __typename?: "ReorderPlanInterviews";
    plan?: {
      __typename?: "Plan";
      id: any;
      planInterviews: Array<{
        __typename?: "PlanInterview";
        id: any;
        order: number;
      }>;
    } | null;
  } | null;
};

export type ReorderPlanQuestionGroupsMutationVariables = Exact<{
  planId: Scalars["ID"];
  interviewId: Scalars["ID"];
  questionGroupIds:
    | Array<InputMaybe<Scalars["ID"]>>
    | InputMaybe<Scalars["ID"]>;
}>;

export type ReorderPlanQuestionGroupsMutation = {
  __typename?: "Mutation";
  reorderPlanQuestionGroups?: {
    __typename?: "ReorderPlanQuestionGroups";
    planInterview?: {
      __typename?: "PlanInterview";
      id: any;
      questionGroups: Array<{
        __typename?: "PlanQuestionGroup";
        id: any;
        order?: number | null;
      }>;
    } | null;
  } | null;
};

export type ReorderPlanQuestionsMutationVariables = Exact<{
  planId: Scalars["ID"];
  groupId: Scalars["ID"];
  questionIds: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
}>;

export type ReorderPlanQuestionsMutation = {
  __typename?: "Mutation";
  reorderPlanQuestions?: {
    __typename?: "ReorderPlanQuestions";
    questionGroup?: {
      __typename?: "PlanQuestionGroup";
      id: any;
      questions: Array<{
        __typename?: "PlanQuestion";
        id: any;
        order?: number | null;
      }>;
    } | null;
  } | null;
};

export type RequestPlanUpgradeMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RequestPlanUpgradeMutation = {
  __typename?: "Mutation";
  requestPlanUpgrade?: {
    __typename?: "RequestPlanUpgrade";
    success?: boolean | null;
  } | null;
};

export type ShareJobDescriptionMutationVariables = Exact<{
  jobDescriptionId: Scalars["ID"];
  shareToUserIds: Array<Scalars["ID"]> | Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
}>;

export type ShareJobDescriptionMutation = {
  __typename?: "Mutation";
  shareJobDescription?: {
    __typename?: "ShareJobDescription";
    jobDescription: {
      __typename?: "JobDescription";
      id: any;
      internalShares: Array<{ __typename?: "JobDescriptionShare"; id: any }>;
    };
  } | null;
};

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = {
  __typename?: "Mutation";
  signOut?: { __typename?: "SignOut"; isAuthenticated: boolean } | null;
};

export type UnimpersonateMutationVariables = Exact<{ [key: string]: never }>;

export type UnimpersonateMutation = {
  __typename?: "Mutation";
  unimpersonate?: {
    __typename?: "Unimpersonate";
    redirectTo: string;
    currentUser: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      isImpersonated: boolean;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      intercomUserHash: string;
      signUpMethod: UserSignUpMethod;
      persona?: UserPersona | null;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        planEnabled: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
      } | null;
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateCurrentUserBrowserExtensionVersionMutationVariables = Exact<{
  browserExtensionVersion?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateCurrentUserBrowserExtensionVersionMutation = {
  __typename?: "Mutation";
  updateCurrentUserBrowserExtensionVersion?: {
    __typename?: "UpdateCurrentUserBrowserExtensionVersion";
    currentUser: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      email: string;
      profilePicUrl?: string | null;
      isImpersonated: boolean;
      createdAt: any;
      signUpCompletedAt?: any | null;
      signUpState: string;
      enabledFeatures: Array<string>;
      impersonatedFeatures: Array<string>;
      browserExtensionVersion?: string | null;
      intercomUserHash: string;
      signUpMethod: UserSignUpMethod;
      persona?: UserPersona | null;
      organization: {
        __typename?: "Organization";
        id: any;
        name: string;
        freemium: boolean;
        planEnabled: boolean;
        interviewEnabled: boolean;
        defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
        allowPlanJobDescriptionToneOverride: boolean;
        featureAccess?: Array<{
          __typename?: "OrganizationFeatureAccess";
          id: string;
          name: FeatureName;
          settingLevel: FeatureSettingLevel;
          isVisible: boolean;
          showCta: boolean;
          marketingRedirect: boolean;
        }> | null;
      };
      planUserRole?: {
        __typename?: "PlanUserRole";
        id: any;
        name: PlanUserRoleName;
        formattedName?: string | null;
      } | null;
      userRole?: {
        __typename?: "UserRole";
        id: any;
        name: UserRoleName;
        formattedName?: string | null;
        permissions: Array<UserRolePermission>;
        canManageOrganizationSettings?: boolean | null;
        canManageIntegrationSettings?: boolean | null;
        canManageHiringTeam?: boolean | null;
        canManageUserRoles?: boolean | null;
        canCreatePosition?: boolean | null;
        canDeleteCall?: boolean | null;
        canCreateTrainings?: boolean | null;
        canManageAllTrainings?: boolean | null;
        canDeleteCandidates?: boolean | null;
      } | null;
      memberships: Array<{
        __typename?: "Membership";
        position: {
          __typename?: "Position";
          id: any;
          title: string;
          displayTitle: string;
        };
        role: {
          __typename?: "PositionRole";
          id: any;
          permissions: Array<PositionRolePermission>;
          canManageHiringTeam?: boolean | null;
          canCreateTrainings?: boolean | null;
        };
      }>;
    };
  } | null;
};

export type UpdateJobDescriptionMutationVariables = Exact<{
  id: Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
  jobDescriptionState?: InputMaybe<JobDescriptionState>;
}>;

export type UpdateJobDescriptionMutation = {
  __typename?: "Mutation";
  updateJobDescription?: {
    __typename?: "UpdateJobDescription";
    jobDescription: {
      __typename?: "JobDescription";
      id: any;
      title?: string | null;
      jobDescriptionState?: JobDescriptionState | null;
    };
  } | null;
};

export type UpdateJobDescriptionCreatorSessionMutationVariables = Exact<{
  id: Scalars["ID"];
  jobTitle?: InputMaybe<Scalars["String"]>;
  jobTitleTitle?: InputMaybe<Scalars["String"]>;
  locationTitle?: InputMaybe<Scalars["String"]>;
  locationType?: InputMaybe<Scalars["String"]>;
  locationName?: InputMaybe<Scalars["String"]>;
  aboutSectionTitle?: InputMaybe<Scalars["String"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]>;
  aboutSectionJobQualities?: InputMaybe<Scalars["String"]>;
  aboutSectionCandidatePitch?: InputMaybe<Scalars["String"]>;
  responsibilitiesSectionTitle?: InputMaybe<Scalars["String"]>;
  responsibilitiesSectionText?: InputMaybe<Scalars["String"]>;
  responsibilitiesSectionListItems?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
  responsibilitiesSectionInputs?: InputMaybe<Scalars["JSONString"]>;
  requirementsSectionTitle?: InputMaybe<Scalars["String"]>;
  requirementsSectionText?: InputMaybe<Scalars["String"]>;
  requirementsSectionListItems?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
  requirementsSectionInputs?: InputMaybe<Scalars["JSONString"]>;
  eeocStatementTitle?: InputMaybe<Scalars["String"]>;
  eeocStatement?: InputMaybe<Scalars["String"]>;
  aboutRoleTone?: InputMaybe<PlanJobDescriptionTone>;
}>;

export type UpdateJobDescriptionCreatorSessionMutation = {
  __typename?: "Mutation";
  updateJobDescriptionCreatorSession?: {
    __typename?: "UpdateJobDescriptionCreatorSession";
    jobDescriptionCreatorSession: {
      __typename?: "JobDescriptionCreatorSession";
      id: any;
      jobTitle?: string | null;
      jobTitleTitle?: string | null;
      locationTitle?: string | null;
      locationType?: string | null;
      locationName?: string | null;
      aboutSectionTitle?: string | null;
      aboutSectionText?: string | null;
      aboutSectionJobQualities?: string | null;
      aboutSectionCandidatePitch?: string | null;
      responsibilitiesSectionTitle?: string | null;
      responsibilitiesSectionText?: string | null;
      responsibilitiesSectionListItems: Array<string>;
      responsibilitiesSectionInputs?: any | null;
      requirementsSectionTitle?: string | null;
      requirementsSectionText?: string | null;
      requirementsSectionListItems: Array<string>;
      requirementsSectionInputs?: any | null;
      eeocStatementTitle?: string | null;
      eeocStatement?: string | null;
      aboutRoleTone?: PlanJobDescriptionTone | null;
    };
  } | null;
};

export type UpdatePlanInterviewMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
}>;

export type UpdatePlanInterviewMutation = {
  __typename?: "Mutation";
  updatePlanInterview?: {
    __typename?: "UpdatePlanInterview";
    planInterview?: {
      __typename?: "PlanInterview";
      id: any;
      name?: string | null;
    } | null;
  } | null;
};

export type UpdatePlanQuestionMutationVariables = Exact<{
  id: Scalars["ID"];
  questionText: Scalars["String"];
}>;

export type UpdatePlanQuestionMutation = {
  __typename?: "Mutation";
  updatePlanQuestion?: {
    __typename?: "UpdatePlanQuestion";
    planQuestion?: {
      __typename?: "PlanQuestion";
      id: any;
      question?: string | null;
      type: PlanQuestionType;
    } | null;
  } | null;
};

export type UpdatePlanQuestionGroupMutationVariables = Exact<{
  id: Scalars["ID"];
  competency: Scalars["String"];
}>;

export type UpdatePlanQuestionGroupMutation = {
  __typename?: "Mutation";
  updatePlanQuestionGroup?: {
    __typename?: "UpdatePlanQuestionGroup";
    planQuestionGroup?: {
      __typename?: "PlanQuestionGroup";
      id: any;
      competency?: string | null;
    } | null;
  } | null;
};

export type AllJobDescriptionsQueryVariables = Exact<{
  pagination?: InputMaybe<SearchTermPaginationDetails>;
  hasPlan?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AllJobDescriptionsQuery = {
  __typename?: "Query";
  paginatedAllJobDescriptions: {
    __typename?: "PaginatedJobDescriptions";
    pageInfo: {
      __typename?: "OffsetPaginationInfo";
      totalRows?: number | null;
      totalPages?: number | null;
    };
    results: Array<{
      __typename?: "JobDescriptionListItem";
      id: any;
      title?: string | null;
      score?: number | null;
      createdAt: any;
      updatedAt: any;
      error?: string | null;
      questionsProcessingStatus?: PlanQuestionsProcessingStatus | null;
      jobDescriptionState?: JobDescriptionState | null;
      creator: { __typename?: "User"; id: any; fullName: string };
      plan?: { __typename?: "Plan"; id: any } | null;
    }>;
  };
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: any;
    firstName?: string | null;
    lastName?: string | null;
    fullName: string;
    email: string;
    profilePicUrl?: string | null;
    isImpersonated: boolean;
    createdAt: any;
    signUpCompletedAt?: any | null;
    signUpState: string;
    enabledFeatures: Array<string>;
    impersonatedFeatures: Array<string>;
    browserExtensionVersion?: string | null;
    intercomUserHash: string;
    signUpMethod: UserSignUpMethod;
    persona?: UserPersona | null;
    organization: {
      __typename?: "Organization";
      id: any;
      name: string;
      freemium: boolean;
      planEnabled: boolean;
      interviewEnabled: boolean;
      defaultPlanJobDescriptionTone: PlanJobDescriptionTone;
      allowPlanJobDescriptionToneOverride: boolean;
      featureAccess?: Array<{
        __typename?: "OrganizationFeatureAccess";
        id: string;
        name: FeatureName;
        settingLevel: FeatureSettingLevel;
        isVisible: boolean;
        showCta: boolean;
        marketingRedirect: boolean;
      }> | null;
    };
    planUserRole?: {
      __typename?: "PlanUserRole";
      id: any;
      name: PlanUserRoleName;
      formattedName?: string | null;
    } | null;
    userRole?: {
      __typename?: "UserRole";
      id: any;
      name: UserRoleName;
      formattedName?: string | null;
      permissions: Array<UserRolePermission>;
      canManageOrganizationSettings?: boolean | null;
      canManageIntegrationSettings?: boolean | null;
      canManageHiringTeam?: boolean | null;
      canManageUserRoles?: boolean | null;
      canCreatePosition?: boolean | null;
      canDeleteCall?: boolean | null;
      canCreateTrainings?: boolean | null;
      canManageAllTrainings?: boolean | null;
      canDeleteCandidates?: boolean | null;
    } | null;
    memberships: Array<{
      __typename?: "Membership";
      position: {
        __typename?: "Position";
        id: any;
        title: string;
        displayTitle: string;
      };
      role: {
        __typename?: "PositionRole";
        id: any;
        permissions: Array<PositionRolePermission>;
        canManageHiringTeam?: boolean | null;
        canCreateTrainings?: boolean | null;
      };
    }>;
  } | null;
};

export type JobDescriptionAnalysisQueryVariables = Exact<{
  text: Scalars["String"];
  blocks: Array<TextBlock> | TextBlock;
  editorContent: Scalars["JSONString"];
  id: Scalars["ID"];
  whitelistedWords?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type JobDescriptionAnalysisQuery = {
  __typename?: "Query";
  jobDescriptionAnalysis?: {
    __typename?: "JobDescriptionAnalysis";
    numWords: number;
    readingTime: string;
    readingTimeMinutesMin: number;
    readingTimeMinutesMax: number;
    readabilityScore: string;
    readabilityScoreRaw: number;
    score: number;
    bestPractices: Array<{
      __typename?: "BestPractice";
      key: string;
      text: string;
      value: boolean;
    }>;
    annotations: Array<{
      __typename?: "Annotation";
      category: string;
      start: number;
      end: number;
      text: string;
      problem: string;
      suggestions?: Array<string> | null;
      value?: number | null;
    }>;
    annotationConfigs: Array<{
      __typename?: "AnnotationConfig";
      category: string;
      color: string;
      style: AnnotationTypeEnum;
      description: string;
      priority: number;
    }>;
  } | null;
};

export type JobDescriptionContentQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type JobDescriptionContentQuery = {
  __typename?: "Query";
  jobDescription: {
    __typename?: "JobDescription";
    id: any;
    title?: string | null;
    editorContent?: any | null;
  };
};

export type JobDescriptionCreatorSessionQueryVariables = Exact<{
  jobDescriptionId: Scalars["ID"];
}>;

export type JobDescriptionCreatorSessionQuery = {
  __typename?: "Query";
  jobDescriptionCreatorSession: {
    __typename?: "JobDescriptionCreatorSession";
    id: any;
    jobTitle?: string | null;
    jobTitleTitle?: string | null;
    jobDescriptionId: string;
    locationTitle?: string | null;
    locationType?: string | null;
    locationName?: string | null;
    aboutSectionTitle?: string | null;
    aboutSectionText?: string | null;
    aboutSectionJobQualities?: string | null;
    aboutSectionCandidatePitch?: string | null;
    responsibilitiesSectionTitle?: string | null;
    responsibilitiesSectionText?: string | null;
    responsibilitiesSectionListItems: Array<string>;
    responsibilitiesSectionInputs?: any | null;
    requirementsSectionTitle?: string | null;
    requirementsSectionText?: string | null;
    requirementsSectionListItems: Array<string>;
    requirementsSectionInputs?: any | null;
    eeocStatementTitle?: string | null;
    eeocStatement?: string | null;
    aboutRoleTone?: PlanJobDescriptionTone | null;
  };
};

export type JobDescriptionListItemFragment = {
  __typename?: "JobDescriptionListItem";
  id: any;
  title?: string | null;
  score?: number | null;
  createdAt: any;
  updatedAt: any;
  error?: string | null;
  questionsProcessingStatus?: PlanQuestionsProcessingStatus | null;
  jobDescriptionState?: JobDescriptionState | null;
  creator: { __typename?: "User"; id: any; fullName: string };
  plan?: { __typename?: "Plan"; id: any } | null;
};

export type JobDescriptionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type JobDescriptionQuery = {
  __typename?: "Query";
  jobDescription: {
    __typename?: "JobDescription";
    id: any;
    organizationId: any;
    createdAt: any;
    updatedAt: any;
    title?: string | null;
    score?: number | null;
    editorContent?: any | null;
    bestPractices?: any | null;
    numWords?: number | null;
    readingTime?: string | null;
    readingTimeMinutesMin?: number | null;
    readingTimeMinutesMax?: number | null;
    readabilityScore?: string | null;
    readabilityScoreRaw?: number | null;
    whitelistedWords: Array<string | null>;
    questionsProcessingStatus?: PlanQuestionsProcessingStatus | null;
    questionsProcessingCompletedAt?: any | null;
    lastModifiedAt?: any | null;
    jobDescriptionState?: JobDescriptionState | null;
    templateId?: any | null;
    creator: {
      __typename?: "User";
      id: any;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
      email: string;
    };
    questionGroups?: Array<{
      __typename?: "PlanQuestionGroup";
      id: any;
      competency?: string | null;
      referenceText?: string | null;
      jobDescriptionId: any;
      source?: QuestionGroupSource | null;
      questions: Array<{
        __typename?: "PlanQuestion";
        id: any;
        question?: string | null;
        type: PlanQuestionType;
      }>;
    }> | null;
    plan?: { __typename?: "Plan"; id: any } | null;
    customHeaderSections: Array<{
      __typename?: "JobDescriptionTemplateCustomSection";
      header: string;
      text: string;
      order: number;
    }>;
    customFooterSections: Array<{
      __typename?: "JobDescriptionTemplateCustomSection";
      header: string;
      text: string;
      order: number;
    }>;
  };
};

export type JobDescriptionSharesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type JobDescriptionSharesQuery = {
  __typename?: "Query";
  jobDescription: {
    __typename?: "JobDescription";
    id: any;
    title?: string | null;
    shareableUsers: Array<{
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
    }>;
    internalShares: Array<{
      __typename?: "JobDescriptionShare";
      id: any;
      createdAt: any;
      updatedAt: any;
      sharedTo: {
        __typename?: "User";
        id: any;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName: string;
        profilePicUrl?: string | null;
      };
    }>;
    visibleTo: {
      __typename?: "PaginatedJobDescriptionVisibleTo";
      resultCount: number;
      results: Array<{
        __typename?: "JobDescriptionVisibleTo";
        id: string;
        role: string;
        user: {
          __typename?: "User";
          id: any;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName: string;
          profilePicUrl?: string | null;
        };
      }>;
    };
  };
};

export type JobDescriptionVisibleToFragment = {
  __typename?: "PaginatedJobDescriptionVisibleTo";
  resultCount: number;
  results: Array<{
    __typename?: "JobDescriptionVisibleTo";
    id: string;
    role: string;
    user: {
      __typename?: "User";
      id: any;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      fullName: string;
      profilePicUrl?: string | null;
    };
  }>;
};

export type JobDescriptionsQueryVariables = Exact<{
  pagination?: InputMaybe<SearchTermPaginationDetails>;
  hasPlan?: InputMaybe<Scalars["Boolean"]>;
}>;

export type JobDescriptionsQuery = {
  __typename?: "Query";
  paginatedJobDescriptions: {
    __typename?: "PaginatedJobDescriptions";
    pageInfo: {
      __typename?: "OffsetPaginationInfo";
      totalRows?: number | null;
      totalPages?: number | null;
    };
    results: Array<{
      __typename?: "JobDescriptionListItem";
      id: any;
      title?: string | null;
      score?: number | null;
      createdAt: any;
      updatedAt: any;
      error?: string | null;
      questionsProcessingStatus?: PlanQuestionsProcessingStatus | null;
      jobDescriptionState?: JobDescriptionState | null;
      creator: { __typename?: "User"; id: any; fullName: string };
      plan?: { __typename?: "Plan"; id: any } | null;
    }>;
  };
};

export type PartnerAccessQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type PartnerAccessQuery = {
  __typename?: "Query";
  partnerAccess?: { __typename?: "PartnerAccess"; hasAccess: boolean } | null;
};

export type PlanQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PlanQuery = {
  __typename?: "Query";
  plan: {
    __typename?: "Plan";
    id: any;
    planProcessingStatus?: PlanProcessingStatus | null;
    createdAt: any;
    jobDescription: {
      __typename?: "JobDescription";
      id: any;
      title?: string | null;
      editorContent?: any | null;
      lastModifiedAt?: any | null;
      jobDescriptionState?: JobDescriptionState | null;
    };
    planInterviews: Array<{
      __typename?: "PlanInterview";
      id: any;
      name?: string | null;
      questionGroups: Array<{
        __typename?: "PlanQuestionGroup";
        id: any;
        planInterviewId?: any | null;
        competency?: string | null;
        referenceText?: string | null;
        source?: QuestionGroupSource | null;
        questions: Array<{
          __typename?: "PlanQuestion";
          id: any;
          groupId: any;
          question?: string | null;
          type: PlanQuestionType;
        }>;
      }>;
    }>;
  };
};

export type PlanQuestionsStatusQueryVariables = Exact<{
  jobDescriptionId: Scalars["ID"];
}>;

export type PlanQuestionsStatusQuery = {
  __typename?: "Query";
  planQuestionsStatus: {
    __typename?: "PlanQuestionsStatus";
    jobDescriptionId: string;
    questionsProcessingStatus?: PlanQuestionsProcessingStatus | null;
  };
};

export type PlanStatusQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PlanStatusQuery = {
  __typename?: "Query";
  plan: {
    __typename?: "Plan";
    id: any;
    planProcessingStatus?: PlanProcessingStatus | null;
  };
};

export type SharedJobDescriptionsQueryVariables = Exact<{
  pagination?: InputMaybe<SearchTermPaginationDetails>;
  hasPlan?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SharedJobDescriptionsQuery = {
  __typename?: "Query";
  paginatedSharedJobDescriptions: {
    __typename?: "PaginatedJobDescriptions";
    pageInfo: {
      __typename?: "OffsetPaginationInfo";
      totalRows?: number | null;
      totalPages?: number | null;
    };
    results: Array<{
      __typename?: "JobDescriptionListItem";
      id: any;
      title?: string | null;
      score?: number | null;
      createdAt: any;
      updatedAt: any;
      error?: string | null;
      questionsProcessingStatus?: PlanQuestionsProcessingStatus | null;
      jobDescriptionState?: JobDescriptionState | null;
      creator: { __typename?: "User"; id: any; fullName: string };
      plan?: { __typename?: "Plan"; id: any } | null;
    }>;
  };
};

export const CreatorTemplateSectionFragmentDoc = gql`
  fragment CreatorTemplateSection on JobDescriptionTemplateCustomSection {
    header
    text
    order
  }
`;
export const PlanOrganizationFeatureAccessFragmentDoc = gql`
  fragment PlanOrganizationFeatureAccess on OrganizationFeatureAccess {
    id
    name
    settingLevel
    isVisible
    showCta
    marketingRedirect
  }
`;
export const PlanOrganizationFragmentDoc = gql`
  fragment PlanOrganization on Organization {
    id
    name
    freemium
    planEnabled
    interviewEnabled
    defaultPlanJobDescriptionTone
    allowPlanJobDescriptionToneOverride
    featureAccess {
      ...PlanOrganizationFeatureAccess
    }
  }
  ${PlanOrganizationFeatureAccessFragmentDoc}
`;
export const CurrentPlanUserFragmentDoc = gql`
  fragment CurrentPlanUser on User {
    id
    firstName
    lastName
    fullName
    email
    profilePicUrl
    isImpersonated
    organization {
      ...PlanOrganization
    }
    createdAt
    signUpCompletedAt
    signUpState
    enabledFeatures
    impersonatedFeatures
    browserExtensionVersion
    intercomUserHash
    signUpMethod
    persona
    planUserRole {
      id
      name
      formattedName @client
    }
    userRole {
      id
      name
      formattedName @client
      permissions
      canManageOrganizationSettings @client
      canManageIntegrationSettings @client
      canManageHiringTeam @client
      canManageUserRoles @client
      canCreatePosition @client
      canDeleteCall @client
      canCreateTrainings @client
      canManageAllTrainings @client
      canDeleteCandidates @client
    }
    memberships {
      position {
        id
        title
        displayTitle
      }
      role {
        id
        permissions
        canManageHiringTeam @client
        canCreateTrainings @client
      }
    }
  }
  ${PlanOrganizationFragmentDoc}
`;
export const PlanQuestionFragmentDoc = gql`
  fragment PlanQuestion on PlanQuestion {
    id
    groupId
    question
    type
  }
`;
export const PlanQuestionGroupFragmentDoc = gql`
  fragment PlanQuestionGroup on PlanQuestionGroup {
    id
    planInterviewId
    competency
    referenceText
    source
    questions {
      ...PlanQuestion
    }
  }
  ${PlanQuestionFragmentDoc}
`;
export const PlanInterviewFragmentDoc = gql`
  fragment PlanInterview on PlanInterview {
    id
    name
    questionGroups {
      ...PlanQuestionGroup
    }
  }
  ${PlanQuestionGroupFragmentDoc}
`;
export const PlanFragmentDoc = gql`
  fragment Plan on Plan {
    id
    planProcessingStatus
    createdAt
    jobDescription {
      id
      title
      editorContent
      lastModifiedAt
      jobDescriptionState
    }
    planInterviews {
      ...PlanInterview
    }
  }
  ${PlanInterviewFragmentDoc}
`;
export const JobDescriptionQuestionGroupFragmentDoc = gql`
  fragment JobDescriptionQuestionGroup on PlanQuestionGroup {
    id
    competency
    referenceText
    jobDescriptionId
    source
    questions {
      id
      question
      type
    }
  }
`;
export const JobDescriptionListItemFragmentDoc = gql`
  fragment JobDescriptionListItem on JobDescriptionListItem {
    id
    title
    score
    createdAt
    updatedAt
    creator {
      id
      fullName
    }
    error
    questionsProcessingStatus
    jobDescriptionState
    plan {
      id
    }
  }
`;
export const ShareUserFragmentDoc = gql`
  fragment ShareUser on User {
    id
    email
    firstName
    lastName
    fullName
    profilePicUrl
  }
`;
export const JobDescriptionVisibleToFragmentDoc = gql`
  fragment JobDescriptionVisibleTo on PaginatedJobDescriptionVisibleTo {
    resultCount
    results {
      id
      role
      user {
        ...ShareUser
      }
    }
  }
  ${ShareUserFragmentDoc}
`;
export const AddPlanInterviewDocument = gql`
  mutation AddPlanInterview($planId: ID!, $name: String!, $order: Int!) {
    addPlanInterview(planId: $planId, name: $name, order: $order) {
      planInterview {
        ...PlanInterview
      }
    }
  }
  ${PlanInterviewFragmentDoc}
`;

/**
 * __useAddPlanInterviewMutation__
 *
 * To run a mutation, you first call `useAddPlanInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanInterviewMutation, { data, loading, error }] = useAddPlanInterviewMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddPlanInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPlanInterviewMutation,
    AddPlanInterviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPlanInterviewMutation,
    AddPlanInterviewMutationVariables
  >(AddPlanInterviewDocument, options);
}
export type AddPlanInterviewMutationHookResult = ReturnType<
  typeof useAddPlanInterviewMutation
>;
export type AddPlanInterviewMutationResult =
  Apollo.MutationResult<AddPlanInterviewMutation>;
export type AddPlanInterviewMutationOptions = Apollo.BaseMutationOptions<
  AddPlanInterviewMutation,
  AddPlanInterviewMutationVariables
>;
export const AddPlanQuestionDocument = gql`
  mutation AddPlanQuestion(
    $planId: ID!
    $groupId: ID!
    $text: String!
    $order: Int!
  ) {
    addPlanQuestion(
      planId: $planId
      groupId: $groupId
      text: $text
      order: $order
    ) {
      question {
        ...PlanQuestion
      }
    }
  }
  ${PlanQuestionFragmentDoc}
`;

/**
 * __useAddPlanQuestionMutation__
 *
 * To run a mutation, you first call `useAddPlanQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanQuestionMutation, { data, loading, error }] = useAddPlanQuestionMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      groupId: // value for 'groupId'
 *      text: // value for 'text'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddPlanQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPlanQuestionMutation,
    AddPlanQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPlanQuestionMutation,
    AddPlanQuestionMutationVariables
  >(AddPlanQuestionDocument, options);
}
export type AddPlanQuestionMutationHookResult = ReturnType<
  typeof useAddPlanQuestionMutation
>;
export type AddPlanQuestionMutationResult =
  Apollo.MutationResult<AddPlanQuestionMutation>;
export type AddPlanQuestionMutationOptions = Apollo.BaseMutationOptions<
  AddPlanQuestionMutation,
  AddPlanQuestionMutationVariables
>;
export const AddPlanQuestionGroupDocument = gql`
  mutation AddPlanQuestionGroup(
    $planId: ID!
    $interviewId: ID!
    $competency: String!
    $order: Int!
  ) {
    addPlanQuestionGroup(
      planId: $planId
      interviewId: $interviewId
      competency: $competency
      order: $order
    ) {
      questionGroup {
        ...PlanQuestionGroup
      }
    }
  }
  ${PlanQuestionGroupFragmentDoc}
`;

/**
 * __useAddPlanQuestionGroupMutation__
 *
 * To run a mutation, you first call `useAddPlanQuestionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanQuestionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanQuestionGroupMutation, { data, loading, error }] = useAddPlanQuestionGroupMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      interviewId: // value for 'interviewId'
 *      competency: // value for 'competency'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddPlanQuestionGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPlanQuestionGroupMutation,
    AddPlanQuestionGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPlanQuestionGroupMutation,
    AddPlanQuestionGroupMutationVariables
  >(AddPlanQuestionGroupDocument, options);
}
export type AddPlanQuestionGroupMutationHookResult = ReturnType<
  typeof useAddPlanQuestionGroupMutation
>;
export type AddPlanQuestionGroupMutationResult =
  Apollo.MutationResult<AddPlanQuestionGroupMutation>;
export type AddPlanQuestionGroupMutationOptions = Apollo.BaseMutationOptions<
  AddPlanQuestionGroupMutation,
  AddPlanQuestionGroupMutationVariables
>;
export const CreateJobDescriptionDocument = gql`
  mutation CreateJobDescription(
    $title: String!
    $jobDescriptionState: JobDescriptionState!
  ) {
    createJobDescription(
      title: $title
      jobDescriptionState: $jobDescriptionState
    ) {
      jobDescription {
        id
        title
      }
    }
  }
`;

/**
 * __useCreateJobDescriptionMutation__
 *
 * To run a mutation, you first call `useCreateJobDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobDescriptionMutation, { data, loading, error }] = useCreateJobDescriptionMutation({
 *   variables: {
 *      title: // value for 'title'
 *      jobDescriptionState: // value for 'jobDescriptionState'
 *   },
 * });
 */
export function useCreateJobDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJobDescriptionMutation,
    CreateJobDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateJobDescriptionMutation,
    CreateJobDescriptionMutationVariables
  >(CreateJobDescriptionDocument, options);
}
export type CreateJobDescriptionMutationHookResult = ReturnType<
  typeof useCreateJobDescriptionMutation
>;
export type CreateJobDescriptionMutationResult =
  Apollo.MutationResult<CreateJobDescriptionMutation>;
export type CreateJobDescriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateJobDescriptionMutation,
  CreateJobDescriptionMutationVariables
>;
export const CreatePlanDocument = gql`
  mutation CreatePlan(
    $numberOfInterviews: Int!
    $jobDescriptionId: ID!
    $jobDescriptionText: String!
    $includeRecruiterScreen: Boolean!
    $additionalAreas: String
  ) {
    createPlan(
      numberOfInterviews: $numberOfInterviews
      jobDescriptionId: $jobDescriptionId
      jobDescriptionText: $jobDescriptionText
      includeRecruiterScreen: $includeRecruiterScreen
      additionalAreas: $additionalAreas
    ) {
      planId
    }
  }
`;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      numberOfInterviews: // value for 'numberOfInterviews'
 *      jobDescriptionId: // value for 'jobDescriptionId'
 *      jobDescriptionText: // value for 'jobDescriptionText'
 *      includeRecruiterScreen: // value for 'includeRecruiterScreen'
 *      additionalAreas: // value for 'additionalAreas'
 *   },
 * });
 */
export function useCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlanMutation,
    CreatePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(
    CreatePlanDocument,
    options
  );
}
export type CreatePlanMutationHookResult = ReturnType<
  typeof useCreatePlanMutation
>;
export type CreatePlanMutationResult =
  Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<
  CreatePlanMutation,
  CreatePlanMutationVariables
>;
export const DeleteJobDescriptionDocument = gql`
  mutation DeleteJobDescription($id: ID!) {
    deleteJobDescription(id: $id) {
      success
    }
  }
`;

/**
 * __useDeleteJobDescriptionMutation__
 *
 * To run a mutation, you first call `useDeleteJobDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobDescriptionMutation, { data, loading, error }] = useDeleteJobDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJobDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteJobDescriptionMutation,
    DeleteJobDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteJobDescriptionMutation,
    DeleteJobDescriptionMutationVariables
  >(DeleteJobDescriptionDocument, options);
}
export type DeleteJobDescriptionMutationHookResult = ReturnType<
  typeof useDeleteJobDescriptionMutation
>;
export type DeleteJobDescriptionMutationResult =
  Apollo.MutationResult<DeleteJobDescriptionMutation>;
export type DeleteJobDescriptionMutationOptions = Apollo.BaseMutationOptions<
  DeleteJobDescriptionMutation,
  DeleteJobDescriptionMutationVariables
>;
export const DeletePlanInterviewDocument = gql`
  mutation DeletePlanInterview($id: ID!) {
    deletePlanInterview(id: $id) {
      success
    }
  }
`;

/**
 * __useDeletePlanInterviewMutation__
 *
 * To run a mutation, you first call `useDeletePlanInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanInterviewMutation, { data, loading, error }] = useDeletePlanInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlanInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlanInterviewMutation,
    DeletePlanInterviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePlanInterviewMutation,
    DeletePlanInterviewMutationVariables
  >(DeletePlanInterviewDocument, options);
}
export type DeletePlanInterviewMutationHookResult = ReturnType<
  typeof useDeletePlanInterviewMutation
>;
export type DeletePlanInterviewMutationResult =
  Apollo.MutationResult<DeletePlanInterviewMutation>;
export type DeletePlanInterviewMutationOptions = Apollo.BaseMutationOptions<
  DeletePlanInterviewMutation,
  DeletePlanInterviewMutationVariables
>;
export const DeletePlanQuestionDocument = gql`
  mutation DeletePlanQuestion($id: ID!) {
    deletePlanQuestion(id: $id) {
      success
    }
  }
`;

/**
 * __useDeletePlanQuestionMutation__
 *
 * To run a mutation, you first call `useDeletePlanQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanQuestionMutation, { data, loading, error }] = useDeletePlanQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlanQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlanQuestionMutation,
    DeletePlanQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePlanQuestionMutation,
    DeletePlanQuestionMutationVariables
  >(DeletePlanQuestionDocument, options);
}
export type DeletePlanQuestionMutationHookResult = ReturnType<
  typeof useDeletePlanQuestionMutation
>;
export type DeletePlanQuestionMutationResult =
  Apollo.MutationResult<DeletePlanQuestionMutation>;
export type DeletePlanQuestionMutationOptions = Apollo.BaseMutationOptions<
  DeletePlanQuestionMutation,
  DeletePlanQuestionMutationVariables
>;
export const DeletePlanQuestionGroupDocument = gql`
  mutation DeletePlanQuestionGroup($id: ID!) {
    deletePlanQuestionGroup(id: $id) {
      success
    }
  }
`;

/**
 * __useDeletePlanQuestionGroupMutation__
 *
 * To run a mutation, you first call `useDeletePlanQuestionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanQuestionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanQuestionGroupMutation, { data, loading, error }] = useDeletePlanQuestionGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlanQuestionGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlanQuestionGroupMutation,
    DeletePlanQuestionGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePlanQuestionGroupMutation,
    DeletePlanQuestionGroupMutationVariables
  >(DeletePlanQuestionGroupDocument, options);
}
export type DeletePlanQuestionGroupMutationHookResult = ReturnType<
  typeof useDeletePlanQuestionGroupMutation
>;
export type DeletePlanQuestionGroupMutationResult =
  Apollo.MutationResult<DeletePlanQuestionGroupMutation>;
export type DeletePlanQuestionGroupMutationOptions = Apollo.BaseMutationOptions<
  DeletePlanQuestionGroupMutation,
  DeletePlanQuestionGroupMutationVariables
>;
export const GenerateAboutRoleDocument = gql`
  mutation GenerateAboutRole(
    $id: ID!
    $jobTitle: String!
    $uniqueAspects: String!
    $pitch: String!
    $customTone: PlanJobDescriptionTone
  ) {
    generateAboutRole(
      id: $id
      jobTitle: $jobTitle
      uniqueAspects: $uniqueAspects
      pitch: $pitch
      customTone: $customTone
    ) {
      aboutRole
    }
  }
`;

/**
 * __useGenerateAboutRoleMutation__
 *
 * To run a mutation, you first call `useGenerateAboutRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAboutRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAboutRoleMutation, { data, loading, error }] = useGenerateAboutRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jobTitle: // value for 'jobTitle'
 *      uniqueAspects: // value for 'uniqueAspects'
 *      pitch: // value for 'pitch'
 *      customTone: // value for 'customTone'
 *   },
 * });
 */
export function useGenerateAboutRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateAboutRoleMutation,
    GenerateAboutRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateAboutRoleMutation,
    GenerateAboutRoleMutationVariables
  >(GenerateAboutRoleDocument, options);
}
export type GenerateAboutRoleMutationHookResult = ReturnType<
  typeof useGenerateAboutRoleMutation
>;
export type GenerateAboutRoleMutationResult =
  Apollo.MutationResult<GenerateAboutRoleMutation>;
export type GenerateAboutRoleMutationOptions = Apollo.BaseMutationOptions<
  GenerateAboutRoleMutation,
  GenerateAboutRoleMutationVariables
>;
export const GeneratePlanQuestionsDocument = gql`
  mutation GeneratePlanQuestions(
    $id: ID!
    $text: String!
    $blocks: [TextBlock!]!
    $regenerate: Boolean
  ) {
    generatePlanQuestions(
      id: $id
      text: $text
      blocks: $blocks
      regenerate: $regenerate
    ) {
      jobDescription {
        id
        questionsProcessingStatus
      }
    }
  }
`;

/**
 * __useGeneratePlanQuestionsMutation__
 *
 * To run a mutation, you first call `useGeneratePlanQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePlanQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePlanQuestionsMutation, { data, loading, error }] = useGeneratePlanQuestionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      blocks: // value for 'blocks'
 *      regenerate: // value for 'regenerate'
 *   },
 * });
 */
export function useGeneratePlanQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratePlanQuestionsMutation,
    GeneratePlanQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratePlanQuestionsMutation,
    GeneratePlanQuestionsMutationVariables
  >(GeneratePlanQuestionsDocument, options);
}
export type GeneratePlanQuestionsMutationHookResult = ReturnType<
  typeof useGeneratePlanQuestionsMutation
>;
export type GeneratePlanQuestionsMutationResult =
  Apollo.MutationResult<GeneratePlanQuestionsMutation>;
export type GeneratePlanQuestionsMutationOptions = Apollo.BaseMutationOptions<
  GeneratePlanQuestionsMutation,
  GeneratePlanQuestionsMutationVariables
>;
export const GenerateRequirementAreaTextDocument = gql`
  mutation GenerateRequirementAreaText(
    $id: ID!
    $jobTitle: String!
    $aboutRole: String!
    $responsibilityArea: String!
    $responsibilities: [String!]!
  ) {
    generateRequirementAreaText(
      id: $id
      jobTitle: $jobTitle
      aboutRole: $aboutRole
      responsibilityArea: $responsibilityArea
      responsibilities: $responsibilities
    ) {
      requirementAreaText
    }
  }
`;

/**
 * __useGenerateRequirementAreaTextMutation__
 *
 * To run a mutation, you first call `useGenerateRequirementAreaTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRequirementAreaTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRequirementAreaTextMutation, { data, loading, error }] = useGenerateRequirementAreaTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jobTitle: // value for 'jobTitle'
 *      aboutRole: // value for 'aboutRole'
 *      responsibilityArea: // value for 'responsibilityArea'
 *      responsibilities: // value for 'responsibilities'
 *   },
 * });
 */
export function useGenerateRequirementAreaTextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateRequirementAreaTextMutation,
    GenerateRequirementAreaTextMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateRequirementAreaTextMutation,
    GenerateRequirementAreaTextMutationVariables
  >(GenerateRequirementAreaTextDocument, options);
}
export type GenerateRequirementAreaTextMutationHookResult = ReturnType<
  typeof useGenerateRequirementAreaTextMutation
>;
export type GenerateRequirementAreaTextMutationResult =
  Apollo.MutationResult<GenerateRequirementAreaTextMutation>;
export type GenerateRequirementAreaTextMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateRequirementAreaTextMutation,
    GenerateRequirementAreaTextMutationVariables
  >;
export const GenerateRequirementAreasDocument = gql`
  mutation GenerateRequirementAreas(
    $id: ID!
    $jobTitle: String!
    $aboutRole: String!
  ) {
    generateRequirementAreas(
      id: $id
      jobTitle: $jobTitle
      aboutRole: $aboutRole
    ) {
      requirementAreas
    }
  }
`;

/**
 * __useGenerateRequirementAreasMutation__
 *
 * To run a mutation, you first call `useGenerateRequirementAreasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRequirementAreasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRequirementAreasMutation, { data, loading, error }] = useGenerateRequirementAreasMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jobTitle: // value for 'jobTitle'
 *      aboutRole: // value for 'aboutRole'
 *   },
 * });
 */
export function useGenerateRequirementAreasMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateRequirementAreasMutation,
    GenerateRequirementAreasMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateRequirementAreasMutation,
    GenerateRequirementAreasMutationVariables
  >(GenerateRequirementAreasDocument, options);
}
export type GenerateRequirementAreasMutationHookResult = ReturnType<
  typeof useGenerateRequirementAreasMutation
>;
export type GenerateRequirementAreasMutationResult =
  Apollo.MutationResult<GenerateRequirementAreasMutation>;
export type GenerateRequirementAreasMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateRequirementAreasMutation,
    GenerateRequirementAreasMutationVariables
  >;
export const GenerateRequirementsDocument = gql`
  mutation GenerateRequirements(
    $id: ID!
    $jobTitle: String!
    $aboutRole: String!
    $responsibilities: [String!]!
    $topic: JobRequirementTopic!
    $partialRequirements: [String!]!
  ) {
    generateRequirements(
      id: $id
      jobTitle: $jobTitle
      aboutRole: $aboutRole
      responsibilities: $responsibilities
      topic: $topic
      partialRequirements: $partialRequirements
    ) {
      requirements
    }
  }
`;

/**
 * __useGenerateRequirementsMutation__
 *
 * To run a mutation, you first call `useGenerateRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRequirementsMutation, { data, loading, error }] = useGenerateRequirementsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jobTitle: // value for 'jobTitle'
 *      aboutRole: // value for 'aboutRole'
 *      responsibilities: // value for 'responsibilities'
 *      topic: // value for 'topic'
 *      partialRequirements: // value for 'partialRequirements'
 *   },
 * });
 */
export function useGenerateRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateRequirementsMutation,
    GenerateRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateRequirementsMutation,
    GenerateRequirementsMutationVariables
  >(GenerateRequirementsDocument, options);
}
export type GenerateRequirementsMutationHookResult = ReturnType<
  typeof useGenerateRequirementsMutation
>;
export type GenerateRequirementsMutationResult =
  Apollo.MutationResult<GenerateRequirementsMutation>;
export type GenerateRequirementsMutationOptions = Apollo.BaseMutationOptions<
  GenerateRequirementsMutation,
  GenerateRequirementsMutationVariables
>;
export const GenerateResponsibilitiesDocument = gql`
  mutation GenerateResponsibilities(
    $id: ID!
    $jobTitle: String!
    $aboutRole: String!
    $responsibilityArea: String!
    $priorSummaries: [String!]!
    $upcomingResponsibilityAreas: [String!]!
  ) {
    generateResponsibilities(
      id: $id
      jobTitle: $jobTitle
      aboutRole: $aboutRole
      responsibilityArea: $responsibilityArea
      priorSummaries: $priorSummaries
      upcomingResponsibilityAreas: $upcomingResponsibilityAreas
    ) {
      responsibilities
    }
  }
`;

/**
 * __useGenerateResponsibilitiesMutation__
 *
 * To run a mutation, you first call `useGenerateResponsibilitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateResponsibilitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateResponsibilitiesMutation, { data, loading, error }] = useGenerateResponsibilitiesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jobTitle: // value for 'jobTitle'
 *      aboutRole: // value for 'aboutRole'
 *      responsibilityArea: // value for 'responsibilityArea'
 *      priorSummaries: // value for 'priorSummaries'
 *      upcomingResponsibilityAreas: // value for 'upcomingResponsibilityAreas'
 *   },
 * });
 */
export function useGenerateResponsibilitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateResponsibilitiesMutation,
    GenerateResponsibilitiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateResponsibilitiesMutation,
    GenerateResponsibilitiesMutationVariables
  >(GenerateResponsibilitiesDocument, options);
}
export type GenerateResponsibilitiesMutationHookResult = ReturnType<
  typeof useGenerateResponsibilitiesMutation
>;
export type GenerateResponsibilitiesMutationResult =
  Apollo.MutationResult<GenerateResponsibilitiesMutation>;
export type GenerateResponsibilitiesMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateResponsibilitiesMutation,
    GenerateResponsibilitiesMutationVariables
  >;
export const GenerateSimplifiedTextDocument = gql`
  mutation GenerateSimplifiedText(
    $id: ID!
    $text: String!
    $jobDescriptionText: String!
  ) {
    generateSimplifiedText(
      id: $id
      text: $text
      jobDescriptionText: $jobDescriptionText
    ) {
      simplifiedText
      problem
      isComplex
    }
  }
`;

/**
 * __useGenerateSimplifiedTextMutation__
 *
 * To run a mutation, you first call `useGenerateSimplifiedTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSimplifiedTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSimplifiedTextMutation, { data, loading, error }] = useGenerateSimplifiedTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      jobDescriptionText: // value for 'jobDescriptionText'
 *   },
 * });
 */
export function useGenerateSimplifiedTextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSimplifiedTextMutation,
    GenerateSimplifiedTextMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateSimplifiedTextMutation,
    GenerateSimplifiedTextMutationVariables
  >(GenerateSimplifiedTextDocument, options);
}
export type GenerateSimplifiedTextMutationHookResult = ReturnType<
  typeof useGenerateSimplifiedTextMutation
>;
export type GenerateSimplifiedTextMutationResult =
  Apollo.MutationResult<GenerateSimplifiedTextMutation>;
export type GenerateSimplifiedTextMutationOptions = Apollo.BaseMutationOptions<
  GenerateSimplifiedTextMutation,
  GenerateSimplifiedTextMutationVariables
>;
export const MoveQuestionGroupToInterviewDocument = gql`
  mutation MoveQuestionGroupToInterview(
    $planId: ID!
    $questionGroupId: ID!
    $sourceInterviewId: ID!
    $destinationInterviewId: ID!
    $destinationQuestionGroupIds: [ID]!
  ) {
    moveQuestionGroupToInterview(
      planId: $planId
      questionGroupId: $questionGroupId
      sourceInterviewId: $sourceInterviewId
      destinationInterviewId: $destinationInterviewId
      destinationQuestionGroupIds: $destinationQuestionGroupIds
    ) {
      plan {
        ...Plan
      }
    }
  }
  ${PlanFragmentDoc}
`;

/**
 * __useMoveQuestionGroupToInterviewMutation__
 *
 * To run a mutation, you first call `useMoveQuestionGroupToInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveQuestionGroupToInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveQuestionGroupToInterviewMutation, { data, loading, error }] = useMoveQuestionGroupToInterviewMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      questionGroupId: // value for 'questionGroupId'
 *      sourceInterviewId: // value for 'sourceInterviewId'
 *      destinationInterviewId: // value for 'destinationInterviewId'
 *      destinationQuestionGroupIds: // value for 'destinationQuestionGroupIds'
 *   },
 * });
 */
export function useMoveQuestionGroupToInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveQuestionGroupToInterviewMutation,
    MoveQuestionGroupToInterviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MoveQuestionGroupToInterviewMutation,
    MoveQuestionGroupToInterviewMutationVariables
  >(MoveQuestionGroupToInterviewDocument, options);
}
export type MoveQuestionGroupToInterviewMutationHookResult = ReturnType<
  typeof useMoveQuestionGroupToInterviewMutation
>;
export type MoveQuestionGroupToInterviewMutationResult =
  Apollo.MutationResult<MoveQuestionGroupToInterviewMutation>;
export type MoveQuestionGroupToInterviewMutationOptions =
  Apollo.BaseMutationOptions<
    MoveQuestionGroupToInterviewMutation,
    MoveQuestionGroupToInterviewMutationVariables
  >;
export const RemoveJobDescriptionShareDocument = gql`
  mutation RemoveJobDescriptionShare($id: ID!) {
    removeJobDescriptionShare(id: $id) {
      jobDescription {
        id
        internalShares {
          id
          sharedTo {
            ...ShareUser
          }
        }
      }
    }
  }
  ${ShareUserFragmentDoc}
`;

/**
 * __useRemoveJobDescriptionShareMutation__
 *
 * To run a mutation, you first call `useRemoveJobDescriptionShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveJobDescriptionShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeJobDescriptionShareMutation, { data, loading, error }] = useRemoveJobDescriptionShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveJobDescriptionShareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveJobDescriptionShareMutation,
    RemoveJobDescriptionShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveJobDescriptionShareMutation,
    RemoveJobDescriptionShareMutationVariables
  >(RemoveJobDescriptionShareDocument, options);
}
export type RemoveJobDescriptionShareMutationHookResult = ReturnType<
  typeof useRemoveJobDescriptionShareMutation
>;
export type RemoveJobDescriptionShareMutationResult =
  Apollo.MutationResult<RemoveJobDescriptionShareMutation>;
export type RemoveJobDescriptionShareMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveJobDescriptionShareMutation,
    RemoveJobDescriptionShareMutationVariables
  >;
export const ReorderPlanInterviewsDocument = gql`
  mutation ReorderPlanInterviews($planId: ID!, $interviewIds: [ID]!) {
    reorderPlanInterviews(planId: $planId, interviewIds: $interviewIds) {
      plan {
        id
        planInterviews {
          id
          order
        }
      }
    }
  }
`;

/**
 * __useReorderPlanInterviewsMutation__
 *
 * To run a mutation, you first call `useReorderPlanInterviewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderPlanInterviewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderPlanInterviewsMutation, { data, loading, error }] = useReorderPlanInterviewsMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      interviewIds: // value for 'interviewIds'
 *   },
 * });
 */
export function useReorderPlanInterviewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderPlanInterviewsMutation,
    ReorderPlanInterviewsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderPlanInterviewsMutation,
    ReorderPlanInterviewsMutationVariables
  >(ReorderPlanInterviewsDocument, options);
}
export type ReorderPlanInterviewsMutationHookResult = ReturnType<
  typeof useReorderPlanInterviewsMutation
>;
export type ReorderPlanInterviewsMutationResult =
  Apollo.MutationResult<ReorderPlanInterviewsMutation>;
export type ReorderPlanInterviewsMutationOptions = Apollo.BaseMutationOptions<
  ReorderPlanInterviewsMutation,
  ReorderPlanInterviewsMutationVariables
>;
export const ReorderPlanQuestionGroupsDocument = gql`
  mutation ReorderPlanQuestionGroups(
    $planId: ID!
    $interviewId: ID!
    $questionGroupIds: [ID]!
  ) {
    reorderPlanQuestionGroups(
      planId: $planId
      interviewId: $interviewId
      questionGroupIds: $questionGroupIds
    ) {
      planInterview {
        id
        questionGroups {
          id
          order
        }
      }
    }
  }
`;

/**
 * __useReorderPlanQuestionGroupsMutation__
 *
 * To run a mutation, you first call `useReorderPlanQuestionGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderPlanQuestionGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderPlanQuestionGroupsMutation, { data, loading, error }] = useReorderPlanQuestionGroupsMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      interviewId: // value for 'interviewId'
 *      questionGroupIds: // value for 'questionGroupIds'
 *   },
 * });
 */
export function useReorderPlanQuestionGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderPlanQuestionGroupsMutation,
    ReorderPlanQuestionGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderPlanQuestionGroupsMutation,
    ReorderPlanQuestionGroupsMutationVariables
  >(ReorderPlanQuestionGroupsDocument, options);
}
export type ReorderPlanQuestionGroupsMutationHookResult = ReturnType<
  typeof useReorderPlanQuestionGroupsMutation
>;
export type ReorderPlanQuestionGroupsMutationResult =
  Apollo.MutationResult<ReorderPlanQuestionGroupsMutation>;
export type ReorderPlanQuestionGroupsMutationOptions =
  Apollo.BaseMutationOptions<
    ReorderPlanQuestionGroupsMutation,
    ReorderPlanQuestionGroupsMutationVariables
  >;
export const ReorderPlanQuestionsDocument = gql`
  mutation ReorderPlanQuestions(
    $planId: ID!
    $groupId: ID!
    $questionIds: [ID]!
  ) {
    reorderPlanQuestions(
      planId: $planId
      groupId: $groupId
      questionIds: $questionIds
    ) {
      questionGroup {
        id
        questions {
          id
          order
        }
      }
    }
  }
`;

/**
 * __useReorderPlanQuestionsMutation__
 *
 * To run a mutation, you first call `useReorderPlanQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderPlanQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderPlanQuestionsMutation, { data, loading, error }] = useReorderPlanQuestionsMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      groupId: // value for 'groupId'
 *      questionIds: // value for 'questionIds'
 *   },
 * });
 */
export function useReorderPlanQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderPlanQuestionsMutation,
    ReorderPlanQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReorderPlanQuestionsMutation,
    ReorderPlanQuestionsMutationVariables
  >(ReorderPlanQuestionsDocument, options);
}
export type ReorderPlanQuestionsMutationHookResult = ReturnType<
  typeof useReorderPlanQuestionsMutation
>;
export type ReorderPlanQuestionsMutationResult =
  Apollo.MutationResult<ReorderPlanQuestionsMutation>;
export type ReorderPlanQuestionsMutationOptions = Apollo.BaseMutationOptions<
  ReorderPlanQuestionsMutation,
  ReorderPlanQuestionsMutationVariables
>;
export const RequestPlanUpgradeDocument = gql`
  mutation RequestPlanUpgrade {
    requestPlanUpgrade {
      success
    }
  }
`;

/**
 * __useRequestPlanUpgradeMutation__
 *
 * To run a mutation, you first call `useRequestPlanUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPlanUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPlanUpgradeMutation, { data, loading, error }] = useRequestPlanUpgradeMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestPlanUpgradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPlanUpgradeMutation,
    RequestPlanUpgradeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPlanUpgradeMutation,
    RequestPlanUpgradeMutationVariables
  >(RequestPlanUpgradeDocument, options);
}
export type RequestPlanUpgradeMutationHookResult = ReturnType<
  typeof useRequestPlanUpgradeMutation
>;
export type RequestPlanUpgradeMutationResult =
  Apollo.MutationResult<RequestPlanUpgradeMutation>;
export type RequestPlanUpgradeMutationOptions = Apollo.BaseMutationOptions<
  RequestPlanUpgradeMutation,
  RequestPlanUpgradeMutationVariables
>;
export const ShareJobDescriptionDocument = gql`
  mutation ShareJobDescription(
    $jobDescriptionId: ID!
    $shareToUserIds: [ID!]!
    $message: String
  ) {
    shareJobDescription(
      jobDescriptionId: $jobDescriptionId
      shareToUserIds: $shareToUserIds
      message: $message
    ) {
      jobDescription {
        id
        internalShares {
          id
        }
      }
    }
  }
`;

/**
 * __useShareJobDescriptionMutation__
 *
 * To run a mutation, you first call `useShareJobDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareJobDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareJobDescriptionMutation, { data, loading, error }] = useShareJobDescriptionMutation({
 *   variables: {
 *      jobDescriptionId: // value for 'jobDescriptionId'
 *      shareToUserIds: // value for 'shareToUserIds'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useShareJobDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareJobDescriptionMutation,
    ShareJobDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareJobDescriptionMutation,
    ShareJobDescriptionMutationVariables
  >(ShareJobDescriptionDocument, options);
}
export type ShareJobDescriptionMutationHookResult = ReturnType<
  typeof useShareJobDescriptionMutation
>;
export type ShareJobDescriptionMutationResult =
  Apollo.MutationResult<ShareJobDescriptionMutation>;
export type ShareJobDescriptionMutationOptions = Apollo.BaseMutationOptions<
  ShareJobDescriptionMutation,
  ShareJobDescriptionMutationVariables
>;
export const SignOutDocument = gql`
  mutation SignOut {
    signOut {
      isAuthenticated
    }
  }
`;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignOutMutation,
    SignOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(
    SignOutDocument,
    options
  );
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<
  SignOutMutation,
  SignOutMutationVariables
>;
export const UnimpersonateDocument = gql`
  mutation Unimpersonate {
    unimpersonate {
      currentUser {
        ...CurrentPlanUser
      }
      redirectTo
    }
  }
  ${CurrentPlanUserFragmentDoc}
`;

/**
 * __useUnimpersonateMutation__
 *
 * To run a mutation, you first call `useUnimpersonateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnimpersonateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unimpersonateMutation, { data, loading, error }] = useUnimpersonateMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnimpersonateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnimpersonateMutation,
    UnimpersonateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnimpersonateMutation,
    UnimpersonateMutationVariables
  >(UnimpersonateDocument, options);
}
export type UnimpersonateMutationHookResult = ReturnType<
  typeof useUnimpersonateMutation
>;
export type UnimpersonateMutationResult =
  Apollo.MutationResult<UnimpersonateMutation>;
export type UnimpersonateMutationOptions = Apollo.BaseMutationOptions<
  UnimpersonateMutation,
  UnimpersonateMutationVariables
>;
export const UpdateCurrentUserBrowserExtensionVersionDocument = gql`
  mutation UpdateCurrentUserBrowserExtensionVersion(
    $browserExtensionVersion: String
  ) {
    updateCurrentUserBrowserExtensionVersion(
      browserExtensionVersion: $browserExtensionVersion
    ) {
      currentUser {
        ...CurrentPlanUser
      }
    }
  }
  ${CurrentPlanUserFragmentDoc}
`;

/**
 * __useUpdateCurrentUserBrowserExtensionVersionMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserBrowserExtensionVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserBrowserExtensionVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserBrowserExtensionVersionMutation, { data, loading, error }] = useUpdateCurrentUserBrowserExtensionVersionMutation({
 *   variables: {
 *      browserExtensionVersion: // value for 'browserExtensionVersion'
 *   },
 * });
 */
export function useUpdateCurrentUserBrowserExtensionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserBrowserExtensionVersionMutation,
    UpdateCurrentUserBrowserExtensionVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserBrowserExtensionVersionMutation,
    UpdateCurrentUserBrowserExtensionVersionMutationVariables
  >(UpdateCurrentUserBrowserExtensionVersionDocument, options);
}
export type UpdateCurrentUserBrowserExtensionVersionMutationHookResult =
  ReturnType<typeof useUpdateCurrentUserBrowserExtensionVersionMutation>;
export type UpdateCurrentUserBrowserExtensionVersionMutationResult =
  Apollo.MutationResult<UpdateCurrentUserBrowserExtensionVersionMutation>;
export type UpdateCurrentUserBrowserExtensionVersionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserBrowserExtensionVersionMutation,
    UpdateCurrentUserBrowserExtensionVersionMutationVariables
  >;
export const UpdateJobDescriptionDocument = gql`
  mutation UpdateJobDescription(
    $id: ID!
    $title: String
    $jobDescriptionState: JobDescriptionState
  ) {
    updateJobDescription(
      id: $id
      title: $title
      jobDescriptionState: $jobDescriptionState
    ) {
      jobDescription {
        id
        title
        jobDescriptionState
      }
    }
  }
`;

/**
 * __useUpdateJobDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateJobDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobDescriptionMutation, { data, loading, error }] = useUpdateJobDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      jobDescriptionState: // value for 'jobDescriptionState'
 *   },
 * });
 */
export function useUpdateJobDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateJobDescriptionMutation,
    UpdateJobDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateJobDescriptionMutation,
    UpdateJobDescriptionMutationVariables
  >(UpdateJobDescriptionDocument, options);
}
export type UpdateJobDescriptionMutationHookResult = ReturnType<
  typeof useUpdateJobDescriptionMutation
>;
export type UpdateJobDescriptionMutationResult =
  Apollo.MutationResult<UpdateJobDescriptionMutation>;
export type UpdateJobDescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateJobDescriptionMutation,
  UpdateJobDescriptionMutationVariables
>;
export const UpdateJobDescriptionCreatorSessionDocument = gql`
  mutation UpdateJobDescriptionCreatorSession(
    $id: ID!
    $jobTitle: String
    $jobTitleTitle: String
    $locationTitle: String
    $locationType: String
    $locationName: String
    $aboutSectionTitle: String
    $aboutSectionText: String
    $aboutSectionJobQualities: String
    $aboutSectionCandidatePitch: String
    $responsibilitiesSectionTitle: String
    $responsibilitiesSectionText: String
    $responsibilitiesSectionListItems: [String]
    $responsibilitiesSectionInputs: JSONString
    $requirementsSectionTitle: String
    $requirementsSectionText: String
    $requirementsSectionListItems: [String]
    $requirementsSectionInputs: JSONString
    $eeocStatementTitle: String
    $eeocStatement: String
    $aboutRoleTone: PlanJobDescriptionTone
  ) {
    updateJobDescriptionCreatorSession(
      id: $id
      jobTitle: $jobTitle
      jobTitleTitle: $jobTitleTitle
      locationTitle: $locationTitle
      locationType: $locationType
      locationName: $locationName
      aboutSectionTitle: $aboutSectionTitle
      aboutSectionText: $aboutSectionText
      aboutSectionJobQualities: $aboutSectionJobQualities
      aboutSectionCandidatePitch: $aboutSectionCandidatePitch
      responsibilitiesSectionTitle: $responsibilitiesSectionTitle
      responsibilitiesSectionText: $responsibilitiesSectionText
      responsibilitiesSectionListItems: $responsibilitiesSectionListItems
      responsibilitiesSectionInputs: $responsibilitiesSectionInputs
      requirementsSectionTitle: $requirementsSectionTitle
      requirementsSectionText: $requirementsSectionText
      requirementsSectionListItems: $requirementsSectionListItems
      requirementsSectionInputs: $requirementsSectionInputs
      eeocStatementTitle: $eeocStatementTitle
      eeocStatement: $eeocStatement
      aboutRoleTone: $aboutRoleTone
    ) {
      jobDescriptionCreatorSession {
        id
        jobTitle
        jobTitleTitle
        locationTitle
        locationType
        locationName
        aboutSectionTitle
        aboutSectionText
        aboutSectionJobQualities
        aboutSectionCandidatePitch
        responsibilitiesSectionTitle
        responsibilitiesSectionText
        responsibilitiesSectionListItems
        responsibilitiesSectionInputs
        requirementsSectionTitle
        requirementsSectionText
        requirementsSectionListItems
        requirementsSectionInputs
        eeocStatementTitle
        eeocStatement
        aboutRoleTone
      }
    }
  }
`;

/**
 * __useUpdateJobDescriptionCreatorSessionMutation__
 *
 * To run a mutation, you first call `useUpdateJobDescriptionCreatorSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobDescriptionCreatorSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobDescriptionCreatorSessionMutation, { data, loading, error }] = useUpdateJobDescriptionCreatorSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jobTitle: // value for 'jobTitle'
 *      jobTitleTitle: // value for 'jobTitleTitle'
 *      locationTitle: // value for 'locationTitle'
 *      locationType: // value for 'locationType'
 *      locationName: // value for 'locationName'
 *      aboutSectionTitle: // value for 'aboutSectionTitle'
 *      aboutSectionText: // value for 'aboutSectionText'
 *      aboutSectionJobQualities: // value for 'aboutSectionJobQualities'
 *      aboutSectionCandidatePitch: // value for 'aboutSectionCandidatePitch'
 *      responsibilitiesSectionTitle: // value for 'responsibilitiesSectionTitle'
 *      responsibilitiesSectionText: // value for 'responsibilitiesSectionText'
 *      responsibilitiesSectionListItems: // value for 'responsibilitiesSectionListItems'
 *      responsibilitiesSectionInputs: // value for 'responsibilitiesSectionInputs'
 *      requirementsSectionTitle: // value for 'requirementsSectionTitle'
 *      requirementsSectionText: // value for 'requirementsSectionText'
 *      requirementsSectionListItems: // value for 'requirementsSectionListItems'
 *      requirementsSectionInputs: // value for 'requirementsSectionInputs'
 *      eeocStatementTitle: // value for 'eeocStatementTitle'
 *      eeocStatement: // value for 'eeocStatement'
 *      aboutRoleTone: // value for 'aboutRoleTone'
 *   },
 * });
 */
export function useUpdateJobDescriptionCreatorSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateJobDescriptionCreatorSessionMutation,
    UpdateJobDescriptionCreatorSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateJobDescriptionCreatorSessionMutation,
    UpdateJobDescriptionCreatorSessionMutationVariables
  >(UpdateJobDescriptionCreatorSessionDocument, options);
}
export type UpdateJobDescriptionCreatorSessionMutationHookResult = ReturnType<
  typeof useUpdateJobDescriptionCreatorSessionMutation
>;
export type UpdateJobDescriptionCreatorSessionMutationResult =
  Apollo.MutationResult<UpdateJobDescriptionCreatorSessionMutation>;
export type UpdateJobDescriptionCreatorSessionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateJobDescriptionCreatorSessionMutation,
    UpdateJobDescriptionCreatorSessionMutationVariables
  >;
export const UpdatePlanInterviewDocument = gql`
  mutation UpdatePlanInterview($id: ID!, $name: String!) {
    updatePlanInterview(id: $id, name: $name) {
      planInterview {
        id
        name
      }
    }
  }
`;

/**
 * __useUpdatePlanInterviewMutation__
 *
 * To run a mutation, you first call `useUpdatePlanInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanInterviewMutation, { data, loading, error }] = useUpdatePlanInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdatePlanInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanInterviewMutation,
    UpdatePlanInterviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlanInterviewMutation,
    UpdatePlanInterviewMutationVariables
  >(UpdatePlanInterviewDocument, options);
}
export type UpdatePlanInterviewMutationHookResult = ReturnType<
  typeof useUpdatePlanInterviewMutation
>;
export type UpdatePlanInterviewMutationResult =
  Apollo.MutationResult<UpdatePlanInterviewMutation>;
export type UpdatePlanInterviewMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanInterviewMutation,
  UpdatePlanInterviewMutationVariables
>;
export const UpdatePlanQuestionDocument = gql`
  mutation UpdatePlanQuestion($id: ID!, $questionText: String!) {
    updatePlanQuestion(id: $id, questionText: $questionText) {
      planQuestion {
        id
        question
        type
      }
    }
  }
`;

/**
 * __useUpdatePlanQuestionMutation__
 *
 * To run a mutation, you first call `useUpdatePlanQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanQuestionMutation, { data, loading, error }] = useUpdatePlanQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionText: // value for 'questionText'
 *   },
 * });
 */
export function useUpdatePlanQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanQuestionMutation,
    UpdatePlanQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlanQuestionMutation,
    UpdatePlanQuestionMutationVariables
  >(UpdatePlanQuestionDocument, options);
}
export type UpdatePlanQuestionMutationHookResult = ReturnType<
  typeof useUpdatePlanQuestionMutation
>;
export type UpdatePlanQuestionMutationResult =
  Apollo.MutationResult<UpdatePlanQuestionMutation>;
export type UpdatePlanQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanQuestionMutation,
  UpdatePlanQuestionMutationVariables
>;
export const UpdatePlanQuestionGroupDocument = gql`
  mutation UpdatePlanQuestionGroup($id: ID!, $competency: String!) {
    updatePlanQuestionGroup(id: $id, competency: $competency) {
      planQuestionGroup {
        id
        competency
      }
    }
  }
`;

/**
 * __useUpdatePlanQuestionGroupMutation__
 *
 * To run a mutation, you first call `useUpdatePlanQuestionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanQuestionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanQuestionGroupMutation, { data, loading, error }] = useUpdatePlanQuestionGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      competency: // value for 'competency'
 *   },
 * });
 */
export function useUpdatePlanQuestionGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanQuestionGroupMutation,
    UpdatePlanQuestionGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlanQuestionGroupMutation,
    UpdatePlanQuestionGroupMutationVariables
  >(UpdatePlanQuestionGroupDocument, options);
}
export type UpdatePlanQuestionGroupMutationHookResult = ReturnType<
  typeof useUpdatePlanQuestionGroupMutation
>;
export type UpdatePlanQuestionGroupMutationResult =
  Apollo.MutationResult<UpdatePlanQuestionGroupMutation>;
export type UpdatePlanQuestionGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanQuestionGroupMutation,
  UpdatePlanQuestionGroupMutationVariables
>;
export const AllJobDescriptionsDocument = gql`
  query AllJobDescriptions(
    $pagination: SearchTermPaginationDetails
    $hasPlan: Boolean
  ) {
    paginatedAllJobDescriptions(pagination: $pagination, hasPlan: $hasPlan) {
      pageInfo {
        totalRows
        totalPages
      }
      results {
        ...JobDescriptionListItem
      }
    }
  }
  ${JobDescriptionListItemFragmentDoc}
`;

/**
 * __useAllJobDescriptionsQuery__
 *
 * To run a query within a React component, call `useAllJobDescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllJobDescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllJobDescriptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      hasPlan: // value for 'hasPlan'
 *   },
 * });
 */
export function useAllJobDescriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllJobDescriptionsQuery,
    AllJobDescriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllJobDescriptionsQuery,
    AllJobDescriptionsQueryVariables
  >(AllJobDescriptionsDocument, options);
}
export function useAllJobDescriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllJobDescriptionsQuery,
    AllJobDescriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllJobDescriptionsQuery,
    AllJobDescriptionsQueryVariables
  >(AllJobDescriptionsDocument, options);
}
export type AllJobDescriptionsQueryHookResult = ReturnType<
  typeof useAllJobDescriptionsQuery
>;
export type AllJobDescriptionsLazyQueryHookResult = ReturnType<
  typeof useAllJobDescriptionsLazyQuery
>;
export type AllJobDescriptionsQueryResult = Apollo.QueryResult<
  AllJobDescriptionsQuery,
  AllJobDescriptionsQueryVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      ...CurrentPlanUser
    }
  }
  ${CurrentPlanUserFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const JobDescriptionAnalysisDocument = gql`
  query JobDescriptionAnalysis(
    $text: String!
    $blocks: [TextBlock!]!
    $editorContent: JSONString!
    $id: ID!
    $whitelistedWords: [String!]
  ) {
    jobDescriptionAnalysis(
      text: $text
      blocks: $blocks
      editorContent: $editorContent
      id: $id
      whitelistedWords: $whitelistedWords
    ) {
      numWords
      readingTime
      readingTimeMinutesMin
      readingTimeMinutesMax
      readabilityScore
      readabilityScoreRaw
      score
      bestPractices {
        key
        text
        value
      }
      annotations {
        category
        start
        end
        text
        problem
        suggestions
        value
      }
      annotationConfigs {
        category
        color
        style
        description
        priority
      }
    }
  }
`;

/**
 * __useJobDescriptionAnalysisQuery__
 *
 * To run a query within a React component, call `useJobDescriptionAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDescriptionAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDescriptionAnalysisQuery({
 *   variables: {
 *      text: // value for 'text'
 *      blocks: // value for 'blocks'
 *      editorContent: // value for 'editorContent'
 *      id: // value for 'id'
 *      whitelistedWords: // value for 'whitelistedWords'
 *   },
 * });
 */
export function useJobDescriptionAnalysisQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobDescriptionAnalysisQuery,
    JobDescriptionAnalysisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    JobDescriptionAnalysisQuery,
    JobDescriptionAnalysisQueryVariables
  >(JobDescriptionAnalysisDocument, options);
}
export function useJobDescriptionAnalysisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobDescriptionAnalysisQuery,
    JobDescriptionAnalysisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    JobDescriptionAnalysisQuery,
    JobDescriptionAnalysisQueryVariables
  >(JobDescriptionAnalysisDocument, options);
}
export type JobDescriptionAnalysisQueryHookResult = ReturnType<
  typeof useJobDescriptionAnalysisQuery
>;
export type JobDescriptionAnalysisLazyQueryHookResult = ReturnType<
  typeof useJobDescriptionAnalysisLazyQuery
>;
export type JobDescriptionAnalysisQueryResult = Apollo.QueryResult<
  JobDescriptionAnalysisQuery,
  JobDescriptionAnalysisQueryVariables
>;
export const JobDescriptionContentDocument = gql`
  query JobDescriptionContent($id: ID!) {
    jobDescription(id: $id) {
      id
      title
      editorContent
    }
  }
`;

/**
 * __useJobDescriptionContentQuery__
 *
 * To run a query within a React component, call `useJobDescriptionContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDescriptionContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDescriptionContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobDescriptionContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobDescriptionContentQuery,
    JobDescriptionContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    JobDescriptionContentQuery,
    JobDescriptionContentQueryVariables
  >(JobDescriptionContentDocument, options);
}
export function useJobDescriptionContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobDescriptionContentQuery,
    JobDescriptionContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    JobDescriptionContentQuery,
    JobDescriptionContentQueryVariables
  >(JobDescriptionContentDocument, options);
}
export type JobDescriptionContentQueryHookResult = ReturnType<
  typeof useJobDescriptionContentQuery
>;
export type JobDescriptionContentLazyQueryHookResult = ReturnType<
  typeof useJobDescriptionContentLazyQuery
>;
export type JobDescriptionContentQueryResult = Apollo.QueryResult<
  JobDescriptionContentQuery,
  JobDescriptionContentQueryVariables
>;
export const JobDescriptionCreatorSessionDocument = gql`
  query JobDescriptionCreatorSession($jobDescriptionId: ID!) {
    jobDescriptionCreatorSession(jobDescriptionId: $jobDescriptionId) {
      id
      jobTitle
      jobTitleTitle
      jobDescriptionId
      locationTitle
      locationType
      locationName
      aboutSectionTitle
      aboutSectionText
      aboutSectionJobQualities
      aboutSectionCandidatePitch
      responsibilitiesSectionTitle
      responsibilitiesSectionText
      responsibilitiesSectionListItems
      responsibilitiesSectionInputs
      requirementsSectionTitle
      requirementsSectionText
      requirementsSectionListItems
      requirementsSectionInputs
      eeocStatementTitle
      eeocStatement
      aboutRoleTone
    }
  }
`;

/**
 * __useJobDescriptionCreatorSessionQuery__
 *
 * To run a query within a React component, call `useJobDescriptionCreatorSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDescriptionCreatorSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDescriptionCreatorSessionQuery({
 *   variables: {
 *      jobDescriptionId: // value for 'jobDescriptionId'
 *   },
 * });
 */
export function useJobDescriptionCreatorSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobDescriptionCreatorSessionQuery,
    JobDescriptionCreatorSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    JobDescriptionCreatorSessionQuery,
    JobDescriptionCreatorSessionQueryVariables
  >(JobDescriptionCreatorSessionDocument, options);
}
export function useJobDescriptionCreatorSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobDescriptionCreatorSessionQuery,
    JobDescriptionCreatorSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    JobDescriptionCreatorSessionQuery,
    JobDescriptionCreatorSessionQueryVariables
  >(JobDescriptionCreatorSessionDocument, options);
}
export type JobDescriptionCreatorSessionQueryHookResult = ReturnType<
  typeof useJobDescriptionCreatorSessionQuery
>;
export type JobDescriptionCreatorSessionLazyQueryHookResult = ReturnType<
  typeof useJobDescriptionCreatorSessionLazyQuery
>;
export type JobDescriptionCreatorSessionQueryResult = Apollo.QueryResult<
  JobDescriptionCreatorSessionQuery,
  JobDescriptionCreatorSessionQueryVariables
>;
export const JobDescriptionDocument = gql`
  query JobDescription($id: ID!) {
    jobDescription(id: $id) {
      id
      organizationId
      createdAt
      updatedAt
      title
      score
      editorContent
      bestPractices
      numWords
      readingTime
      readingTimeMinutesMin
      readingTimeMinutesMax
      readabilityScore
      readabilityScoreRaw
      creator {
        id
        firstName
        lastName
        fullName
        profilePicUrl
        email
      }
      whitelistedWords
      questionsProcessingStatus
      questionsProcessingCompletedAt
      questionGroups {
        ...JobDescriptionQuestionGroup
      }
      lastModifiedAt
      jobDescriptionState
      plan {
        id
      }
      templateId
      customHeaderSections {
        ...CreatorTemplateSection
      }
      customFooterSections {
        ...CreatorTemplateSection
      }
    }
  }
  ${JobDescriptionQuestionGroupFragmentDoc}
  ${CreatorTemplateSectionFragmentDoc}
`;

/**
 * __useJobDescriptionQuery__
 *
 * To run a query within a React component, call `useJobDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDescriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobDescriptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobDescriptionQuery,
    JobDescriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobDescriptionQuery, JobDescriptionQueryVariables>(
    JobDescriptionDocument,
    options
  );
}
export function useJobDescriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobDescriptionQuery,
    JobDescriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobDescriptionQuery, JobDescriptionQueryVariables>(
    JobDescriptionDocument,
    options
  );
}
export type JobDescriptionQueryHookResult = ReturnType<
  typeof useJobDescriptionQuery
>;
export type JobDescriptionLazyQueryHookResult = ReturnType<
  typeof useJobDescriptionLazyQuery
>;
export type JobDescriptionQueryResult = Apollo.QueryResult<
  JobDescriptionQuery,
  JobDescriptionQueryVariables
>;
export const JobDescriptionSharesDocument = gql`
  query JobDescriptionShares($id: ID!) {
    jobDescription(id: $id) {
      id
      title
      shareableUsers {
        ...ShareUser
      }
      internalShares {
        id
        createdAt
        updatedAt
        sharedTo {
          ...ShareUser
        }
      }
      visibleTo {
        ...JobDescriptionVisibleTo
      }
    }
  }
  ${ShareUserFragmentDoc}
  ${JobDescriptionVisibleToFragmentDoc}
`;

/**
 * __useJobDescriptionSharesQuery__
 *
 * To run a query within a React component, call `useJobDescriptionSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDescriptionSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDescriptionSharesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobDescriptionSharesQuery(
  baseOptions: Apollo.QueryHookOptions<
    JobDescriptionSharesQuery,
    JobDescriptionSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    JobDescriptionSharesQuery,
    JobDescriptionSharesQueryVariables
  >(JobDescriptionSharesDocument, options);
}
export function useJobDescriptionSharesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobDescriptionSharesQuery,
    JobDescriptionSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    JobDescriptionSharesQuery,
    JobDescriptionSharesQueryVariables
  >(JobDescriptionSharesDocument, options);
}
export type JobDescriptionSharesQueryHookResult = ReturnType<
  typeof useJobDescriptionSharesQuery
>;
export type JobDescriptionSharesLazyQueryHookResult = ReturnType<
  typeof useJobDescriptionSharesLazyQuery
>;
export type JobDescriptionSharesQueryResult = Apollo.QueryResult<
  JobDescriptionSharesQuery,
  JobDescriptionSharesQueryVariables
>;
export const JobDescriptionsDocument = gql`
  query JobDescriptions(
    $pagination: SearchTermPaginationDetails
    $hasPlan: Boolean
  ) {
    paginatedJobDescriptions(pagination: $pagination, hasPlan: $hasPlan) {
      pageInfo {
        totalRows
        totalPages
      }
      results {
        ...JobDescriptionListItem
      }
    }
  }
  ${JobDescriptionListItemFragmentDoc}
`;

/**
 * __useJobDescriptionsQuery__
 *
 * To run a query within a React component, call `useJobDescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDescriptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      hasPlan: // value for 'hasPlan'
 *   },
 * });
 */
export function useJobDescriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JobDescriptionsQuery,
    JobDescriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobDescriptionsQuery, JobDescriptionsQueryVariables>(
    JobDescriptionsDocument,
    options
  );
}
export function useJobDescriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobDescriptionsQuery,
    JobDescriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    JobDescriptionsQuery,
    JobDescriptionsQueryVariables
  >(JobDescriptionsDocument, options);
}
export type JobDescriptionsQueryHookResult = ReturnType<
  typeof useJobDescriptionsQuery
>;
export type JobDescriptionsLazyQueryHookResult = ReturnType<
  typeof useJobDescriptionsLazyQuery
>;
export type JobDescriptionsQueryResult = Apollo.QueryResult<
  JobDescriptionsQuery,
  JobDescriptionsQueryVariables
>;
export const PartnerAccessDocument = gql`
  query PartnerAccess($email: String!) {
    partnerAccess(email: $email) {
      hasAccess
    }
  }
`;

/**
 * __usePartnerAccessQuery__
 *
 * To run a query within a React component, call `usePartnerAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerAccessQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePartnerAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    PartnerAccessQuery,
    PartnerAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerAccessQuery, PartnerAccessQueryVariables>(
    PartnerAccessDocument,
    options
  );
}
export function usePartnerAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PartnerAccessQuery,
    PartnerAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerAccessQuery, PartnerAccessQueryVariables>(
    PartnerAccessDocument,
    options
  );
}
export type PartnerAccessQueryHookResult = ReturnType<
  typeof usePartnerAccessQuery
>;
export type PartnerAccessLazyQueryHookResult = ReturnType<
  typeof usePartnerAccessLazyQuery
>;
export type PartnerAccessQueryResult = Apollo.QueryResult<
  PartnerAccessQuery,
  PartnerAccessQueryVariables
>;
export const PlanDocument = gql`
  query Plan($id: ID!) {
    plan(id: $id) {
      ...Plan
    }
  }
  ${PlanFragmentDoc}
`;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanQuery(
  baseOptions: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
}
export function usePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(
    PlanDocument,
    options
  );
}
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;
export const PlanQuestionsStatusDocument = gql`
  query PlanQuestionsStatus($jobDescriptionId: ID!) {
    planQuestionsStatus(jobDescriptionId: $jobDescriptionId) {
      jobDescriptionId
      questionsProcessingStatus
    }
  }
`;

/**
 * __usePlanQuestionsStatusQuery__
 *
 * To run a query within a React component, call `usePlanQuestionsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuestionsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuestionsStatusQuery({
 *   variables: {
 *      jobDescriptionId: // value for 'jobDescriptionId'
 *   },
 * });
 */
export function usePlanQuestionsStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlanQuestionsStatusQuery,
    PlanQuestionsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlanQuestionsStatusQuery,
    PlanQuestionsStatusQueryVariables
  >(PlanQuestionsStatusDocument, options);
}
export function usePlanQuestionsStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlanQuestionsStatusQuery,
    PlanQuestionsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlanQuestionsStatusQuery,
    PlanQuestionsStatusQueryVariables
  >(PlanQuestionsStatusDocument, options);
}
export type PlanQuestionsStatusQueryHookResult = ReturnType<
  typeof usePlanQuestionsStatusQuery
>;
export type PlanQuestionsStatusLazyQueryHookResult = ReturnType<
  typeof usePlanQuestionsStatusLazyQuery
>;
export type PlanQuestionsStatusQueryResult = Apollo.QueryResult<
  PlanQuestionsStatusQuery,
  PlanQuestionsStatusQueryVariables
>;
export const PlanStatusDocument = gql`
  query PlanStatus($id: ID!) {
    plan(id: $id) {
      id
      planProcessingStatus
    }
  }
`;

/**
 * __usePlanStatusQuery__
 *
 * To run a query within a React component, call `usePlanStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlanStatusQuery,
    PlanStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanStatusQuery, PlanStatusQueryVariables>(
    PlanStatusDocument,
    options
  );
}
export function usePlanStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlanStatusQuery,
    PlanStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanStatusQuery, PlanStatusQueryVariables>(
    PlanStatusDocument,
    options
  );
}
export type PlanStatusQueryHookResult = ReturnType<typeof usePlanStatusQuery>;
export type PlanStatusLazyQueryHookResult = ReturnType<
  typeof usePlanStatusLazyQuery
>;
export type PlanStatusQueryResult = Apollo.QueryResult<
  PlanStatusQuery,
  PlanStatusQueryVariables
>;
export const SharedJobDescriptionsDocument = gql`
  query SharedJobDescriptions(
    $pagination: SearchTermPaginationDetails
    $hasPlan: Boolean
  ) {
    paginatedSharedJobDescriptions(pagination: $pagination, hasPlan: $hasPlan) {
      pageInfo {
        totalRows
        totalPages
      }
      results {
        ...JobDescriptionListItem
      }
    }
  }
  ${JobDescriptionListItemFragmentDoc}
`;

/**
 * __useSharedJobDescriptionsQuery__
 *
 * To run a query within a React component, call `useSharedJobDescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedJobDescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedJobDescriptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      hasPlan: // value for 'hasPlan'
 *   },
 * });
 */
export function useSharedJobDescriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SharedJobDescriptionsQuery,
    SharedJobDescriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SharedJobDescriptionsQuery,
    SharedJobDescriptionsQueryVariables
  >(SharedJobDescriptionsDocument, options);
}
export function useSharedJobDescriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SharedJobDescriptionsQuery,
    SharedJobDescriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SharedJobDescriptionsQuery,
    SharedJobDescriptionsQueryVariables
  >(SharedJobDescriptionsDocument, options);
}
export type SharedJobDescriptionsQueryHookResult = ReturnType<
  typeof useSharedJobDescriptionsQuery
>;
export type SharedJobDescriptionsLazyQueryHookResult = ReturnType<
  typeof useSharedJobDescriptionsLazyQuery
>;
export type SharedJobDescriptionsQueryResult = Apollo.QueryResult<
  SharedJobDescriptionsQuery,
  SharedJobDescriptionsQueryVariables
>;
