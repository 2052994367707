import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Link, LoadingIndicator } from "../../../../components";
import { useSkillReportsQuery } from "../../../graphql";
import SkillsReportHeader from "./SkillsReportHeader";
import SkillsReportTable from "./SkillsReportTable";

const AnalyticsSkillsReports: React.FC = () => {
  const navigate = useNavigate();

  const { loading, error, data } = useSkillReportsQuery();

  if (loading) {
    return (
      <Center w="100%">
        <LoadingIndicator />
      </Center>
    );
  }

  if (error) {
    return <Alert status="error" description="Error loading report" reload />;
  }

  const reports = data?.currentUser?.organization.skillReports || [];

  return (
    <Box pb="100px">
      <SkillsReportHeader
        startBuildReport={() => navigate("/insights/skill-report")}
        includeDetails={false}
      />
      <Box p="8">
        {reports.length === 0 ? (
          <Center>
            <Text>
              No saved reports. Create a new one{" "}
              <Link href="/insights/skill-report">here</Link>.
            </Text>
          </Center>
        ) : (
          <SkillsReportTable reports={reports} />
        )}
      </Box>
    </Box>
  );
};

export default AnalyticsSkillsReports;
